import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { sendPageView } from '../../services/Analytics';
import { usePrevious } from '../../services/hooks/usePrevious';


const GAListener = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect((): void => {
    if (import.meta.env.VITE_GOOGLE_ANALYTICS) {
      sendPageView(location, prevLocation);
    }
  }, [location, prevLocation]);

  return children;
};

export default GAListener;
