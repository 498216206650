import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from '../../../../interfaces/Signature';
import usePageTitle from '../../../../services/hooks/usePageTitle';
import ProductItem from '../ProductItem/ProductItem';
import styles from './ProductsSection.module.scss';


interface ProductsSectionProps {
  title: string;
  data: Product[]
}

const ProductsSection: FC<ProductsSectionProps> = ({ title, data }): JSX.Element => {
  const { t } = useTranslation();

  usePageTitle(t('pageTitle:appointment'));

  return (
    <section className={styles.productsSection}>
      <h4>{title}</h4>
      <ul>
        {data.map((item, index) => <ProductItem key={index} {...item} />)}
      </ul>
    </section>
  );
};

export default ProductsSection;
