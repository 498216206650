import React from 'react';
import { FCWithChild } from '../../interfaces/Shared';

interface NavigationProps {
  className?: string;
  rootRef?: React.Ref<HTMLDivElement>;
}

const Navigation: FCWithChild<NavigationProps> = ({ className, rootRef, children }) => {
  return (
    <div className={className} ref={rootRef}>
      {children}
    </div>
  );
};

export default Navigation;
