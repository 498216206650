const isEcoLeafGreen = (index: number, grade: number): boolean => {
  const rating = getEcoRating(grade);

  return (
    index < rating
  );
};

const getEcoRating = (grade: number): number => {
  let rating = 0;

  if (grade >= 80) {
    rating = 3;
  } else if (grade >= 60) {
    rating = 2;
  } else if (grade >=50) {
    rating = 1;
  }

  return rating;
};

export { isEcoLeafGreen, getEcoRating };
