import Axios from 'axios';

import { MultiLingualStylesheet } from '../interfaces/Stylesheet';
import CdnRepository from './interfaces/CdnRepository';

export default class CdnRepositoryRemote implements CdnRepository {
  private url: string;

  constructor() {
    if (!import.meta.env.VITE_CDN_BASEURL) {
      throw new Error('CDN_BASEURL not set');
    }
    this.url = import.meta.env.VITE_CDN_BASEURL;
  }

  public getStylesheet(subscriptionId: string): Promise<MultiLingualStylesheet> {
    return Axios.get<MultiLingualStylesheet>(`${this.url}/stylesheets/${subscriptionId}.json`)
      .then((result) => result.data);
  }
}
