export default {
  nl: {
    confirmSection: {
      title: 'De afspraak is gemaakt!',
      agenda: 'Voeg toe aan je agenda',
      changeAppointment: 'Komt het toch niet uit op deze datum?',
      change: 'Wijzigen',
      confirmedAppointment: 'Onze monteur arriveert [shortDate] tussen [startTime] en [endTime]',
      averageVisit: 'Dit bezoek duurt gemiddeld [b][duration][/b], het is afhankelijk van de situatie bij jou thuis',
      expertOnWay: 'Onze expert is onderweg',
      expertPassedBy: 'Onze expert is langsgeweest',
      whoWasThere: 'Wie komt er langs',
      aboutYourVisit: 'Over je bezoek',
      selectedNoTopSlotsTitle: 'Bedankt voor het opgeven van jouw beschikbaarheid!',
      selectedNoTopSlotsDescription: 'Wij zullen een afspraak voor je inplannen en per mail een afspraakbevestiging sturen zodra deze is gemaakt.',
      selectedNoTopSlotsContentTitle: 'We hebben de volgende beschikbare momenten van je ontvangen',
      selectedNoTopSlotsContent: '{{date}} {{periods}}',
    },
  },
  en: {
    confirmSection: {
      title: 'The appointment is scheduled!',
      agenda: 'Add to your calendar',
      changeAppointment: 'Does this date not work for you?',
      change: 'Change',
      confirmedAppointment: 'Our expert arrives on [shortDate] between [startTime] and [endTime] hours',
      averageVisit: 'The visit lasts for approximately [b][duration][/b] and depends on the situation in your home',
      expertOnWay: 'Our expert is on the way',
      expertPassedBy: 'Our expert has stopped by',
      whoWasThere: 'Assigned Expert',
      aboutYourVisit: 'About your visit',
      selectedNoTopSlotsTitle: 'Thank you for providing us with your availability!',
      selectedNoTopSlotsDescription: 'We will schedule an appointment for you and send a confirmation via email.',
      selectedNoTopSlotsContentTitle: 'The availability you provided us with',
      selectedNoTopSlotsContent: '{{date}} {{periods}}',
    },
  }
};
