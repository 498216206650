import { FC, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { NoTopSlot, NoTopSlotRange } from '../../interfaces/NoTopSlot';
import { NoSlotButton } from './NoSlotButton';
import CheckBoxInput from '../CheckBoxInput/CheckBoxInput';
import style from './NoSlotSelectors.module.scss';
import DateTime from '../../helpers/DateTime';

import { getIsAllPeriodsSelected } from '../../services/NoTopSlotService';
import Button from '../Button';
import Card from '../Card/Card';

interface NoSlotSelectorsProps {
  noTopSlots: NoTopSlot[];
  updateSlots: (noTopSlots: NoTopSlot[]) => void;
}

const MAX_SLOTS_TO_SHOW = 7;

const NoSlotSelectors: FC<NoSlotSelectorsProps> = ({ noTopSlots, updateSlots }) => {
  const [slotItemPosition, setSlotItemPosition] = useState<number>(0);
  const { t } = useTranslation();

  const setPeriodOfDay = useCallback(({ targetDay, targetPeriod, isEntireDay }: {
    targetDay: string,
    targetPeriod?: NoTopSlotRange,
    isEntireDay?: boolean
  }): void => {
    const updatedSlots = noTopSlots.map<NoTopSlot>((slot) => {
      const { day, periods } = slot;

      if (day === targetDay) {
        const updatedPeriods = periods.map(period => {
          if (targetPeriod && period.from === targetPeriod.from && period.to === targetPeriod.to) {
            return { ...period, selected: targetPeriod.selected };
          } else if (isEntireDay !== undefined) {
            return { ...period, selected: isEntireDay };
          }

          return period;
        });

        return { ...slot, periods: updatedPeriods };
      }

      return slot;
    });

    updateSlots(updatedSlots);
  }, [noTopSlots, updateSlots]);

  const renderArrow = useCallback((arrow: 'left' | 'right') => {
    const isLeftArrow = arrow === 'left';

    const handleOnClick = () => {
      const updatedSlots: NoTopSlot[] = noTopSlots.map<NoTopSlot>((slot: NoTopSlot) => {
        const { periods } = slot;
        const updatedPeriods = periods.map(period => ({ ...period, selected: false }));

        return { ...slot, periods: updatedPeriods };
      });
      updateSlots(updatedSlots);

      setSlotItemPosition((prevValue) => {
        return isLeftArrow
          ? prevValue - MAX_SLOTS_TO_SHOW
          : prevValue + MAX_SLOTS_TO_SHOW;
      });
    };

    return (
      <Button
        className={style.buttonSize}
        variant="contained"
        icon={isLeftArrow ? 'arrow_back_ios' : 'arrow_forward_ios'}
        onClick={handleOnClick}
        ariaLabel={t(`slots:show${isLeftArrow ? 'Previous' : 'Next'}Slots`)}
      />
    );
  }, [t, noTopSlots, updateSlots]);

  const renderedSlotColumns = useMemo(() => {
    if (!noTopSlots.length) return null;

    const slotsToDisplay = noTopSlots.slice(slotItemPosition, slotItemPosition + MAX_SLOTS_TO_SHOW);

    return (
      <>
        {slotsToDisplay.map(({ day, allowed, periods }) => {
          const areAllPeriodsSelected = getIsAllPeriodsSelected(periods);

          return (
            <div
              key={day}
              className={classnames({
                [style.noTopSlot]: true,
                ['partner-border-color']: areAllPeriodsSelected,
                [style.selected]: areAllPeriodsSelected,
                [style.disabled]: !allowed
              })}
            >
              <span className={classnames(style.label)}>
                <span>{DateTime.formatDate('EEEE', new Date(day))}</span>
                <span className={style.date}>{DateTime.formatDate('dd LLLL', new Date(day))}</span>
              </span>
              <div className={style.noTopSlotPeriods}>
                {!!periods.length && periods.map(({ from, to, selected }) => (
                  <NoSlotButton
                    disabled={!allowed}
                    key={from}
                    text={`${DateTime.getTimeStringFromDate(DateTime.roundToNearestMinutes(from))} - ${
                      DateTime.getTimeStringFromDate(DateTime.roundToNearestMinutes(to))}`}
                    selected={selected}
                    onSelect={(isSelected: boolean): void => {
                      setPeriodOfDay({ targetDay: day, targetPeriod: { from, to, selected: isSelected } });
                    }}
                    className={style.noSlotButton}
                  />
                ))}
              </div>
              <CheckBoxInput
                id={day}
                label={t('noslots:entireDay')}
                smallLabel
                disabled={!allowed}
                isChecked={areAllPeriodsSelected}
                onCheck={(isChecked) => {
                  setPeriodOfDay({ targetDay: day, isEntireDay: isChecked });
                }}
                className={style.entireDay}
              />
            </div>
          );
        })}
      </>
    );
  }, [noTopSlots, setPeriodOfDay, slotItemPosition, t]);

  return (
    <Card classStyle={style.root}>
      {slotItemPosition >= MAX_SLOTS_TO_SHOW && renderArrow('left')}
      {renderedSlotColumns}
      {slotItemPosition + MAX_SLOTS_TO_SHOW < noTopSlots.length && renderArrow('right')}
    </Card>
  );
};

export default NoSlotSelectors;
