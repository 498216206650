import React from 'react';

import { TicketContext } from '../../contexts/TicketContext';
import ContentfulService, { DateRange } from '../ContentfulService';

interface UseFormattingOptions {
  replaceDate?: string | null;
  range?: DateRange;
  defaultValue?: string;
  roundTo?: number;
}

export const useFormatting = (text: string | undefined, options: UseFormattingOptions = {}): { formattedText: string } => {
  const { replaceDate, range, defaultValue, roundTo } = options;

  const { ticket } = React.useContext(TicketContext);
  const [formattedText, setFormattedText] = React.useState(text || '');

  React.useEffect((): void => {
    let val = text || defaultValue || '';

    if (val) {
      val = ContentfulService.convertHtmlTag(val);

      if (ticket) {
        val = ContentfulService.convertTicketTag(val, ticket.hashId);
      }

      if (replaceDate) {
        val = ContentfulService.convertDateTag(val, replaceDate, {
          range,
          roundDurationTo: roundTo
        });
      }
    }

    setFormattedText(val);
  }, [defaultValue, text, replaceDate, range, ticket, roundTo]);

  return { formattedText };
};
