import alert from './alert';
import appointment from './appointment';
import button from './button';
import cancel from './cancel';
import confirm from './confirm';
import contact from './contact';
import customer from './customer';
import error from './error';
import faq from './faq';
import footer from './footer';
import form from './form';
import labels from './labels';
import maintenance from './maintenance';
import question from './question';
import imageAlts from './imageAlts';
import visitcard from './visitcard';
import signature from './signature';
import currency from './currency';

const translations = {
  nl: {
    translation: {
      ...alert.nl,
      ...appointment.nl,
      ...button.nl,
      ...cancel.nl,
      ...confirm.nl,
      ...contact.nl,
      ...currency.nl,
      ...customer.nl,
      ...error.nl,
      ...faq.nl,
      ...footer.nl,
      ...form.nl,
      ...labels.nl,
      ...maintenance.nl,
      ...question.nl,
      ...imageAlts.nl,
      ...visitcard.nl,
      ...signature.nl
    },
  },
  en: {
    translation: {
      ...alert.en,
      ...appointment.en,
      ...button.en,
      ...cancel.en,
      ...confirm.en,
      ...contact.en,
      ...currency.en,
      ...customer.en,
      ...error.en,
      ...faq.en,
      ...footer.en,
      ...form.en,
      ...labels.en,
      ...maintenance.en,
      ...question.en,
      ...imageAlts.en,
      ...visitcard.en,
      ...signature.en
    },
  },
};

export default translations;
