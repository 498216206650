export default {
  nl: {
    contact: {
      title: 'Contact',
      phoneTitle: 'Bel Guidion',
      formDescription: 'Voor vragen kan contact op worden genomen met Guidion.',
      invalidEmail: 'Ongeldig e-mailadres',
      loadingMessage: 'Bericht wordt verzonden ...',
      errorMessage: 'Het verzenden van het bericht is mislukt. Probeer het nogmaals of neem telefonisch contact met ons op.',
      successMessage: 'Bericht is succesvol verzonden.',
      chatWithWhatsApp: 'Chat met Guidion via WhatsApp',
      whatsappPrefilledText: 'Hi, ik heb een vraag over mijn afspraak op adres {{address}}',
      localTariff: 'lokaal tarief',
    }
  },
  en: {
    contact: {
      title: 'Contact',
      phoneTitle: 'Call Guidion',
      formDescription: 'If you have any questions, please contact Guidion.',
      invalidEmail: 'Invalid e-mailaddress',
      loadingMessage: 'Message is being sent ...',
      errorMessage: 'Failed to send the message. Please try again or contact us by phone.',
      successMessage: 'Message was successfully sent',
      chatWithWhatsApp: 'Chat with Guidion via WhatsApp',
      whatsappPrefilledText: 'Hi, I have a question regarding my appointment at {{address}}',
      localTariff: 'local tariff',
    }
  }
};
