import BackendRepository from '../api/interfaces/BackendRepository';
import BackendRepositoryRemote from '../api/BackendRepositoryRemote';

export default class QuestionsService {
  private repository: BackendRepository;

  constructor() {
    this.repository = new BackendRepositoryRemote();
  }

  public async completeQuestionnaire(serviceAppointmentId: string): Promise<void> {
    return this.repository.completeQuestionnaire(serviceAppointmentId)
      .catch((error: Error) => console.error('finish questionnaire error', error));
  }
}
