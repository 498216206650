import { FC } from 'react';

import styles from './StarRating.module.scss';
import MaterialIcon from '../../../components/MaterialIcon/MaterialIcon';

const STAR_SIZE = 16;
const STAR_MARGIN_RIGHT = 6;

interface StarRatingProps {
  value: number;
  starColor?: string;
  starBackgroundColor?: string;
}

const Star: FC<StarRatingProps & { lastElement?: boolean }> = (
  { value, starColor, starBackgroundColor, lastElement }
) => {
  const height = starBackgroundColor ? { height: STAR_SIZE } : undefined;

  const containerStarStyle = {
    width: STAR_SIZE,
    ...(height),
    ...(!lastElement ? { marginRight: STAR_MARGIN_RIGHT } : undefined),
    zIndex: 1,
    position: 'relative' as const,
  };
  const dynamicStarStyle = {
    width: STAR_SIZE * value,
    ...(height),
    alignItems: 'center',
    overflow: 'hidden',
  };

  const dynamicBackgroundStarStyle = {
    width: STAR_SIZE,
    ...(height),
  };

  return (
    <div style={containerStarStyle}>
      <div style={dynamicStarStyle}>
        <MaterialIcon name="star" size={STAR_SIZE} color={starColor} />
      </div>
      {!!starBackgroundColor && (
        <div style={dynamicBackgroundStarStyle} className={styles.backgroundStar}>
          <MaterialIcon name="star" size={STAR_SIZE} color={starBackgroundColor} />
        </div>
      )}
    </div>
  );
};

const StarRating: FC<StarRatingProps> = ({ value, starColor, starBackgroundColor }) => {
  const full = Math.floor(value);
  const decimal = value - full;
  const starValues = new Array(full).fill(1);

  if (decimal) {
    starValues.push(decimal);
  }

  if (starBackgroundColor) {
    const starsLeft = new Array(5 - starValues.length).fill(0);
    starValues.push(...starsLeft);
  }

  return (
    <div className={styles.starsContainer}>
      {starValues.map((value: number, index: number) => (
        <Star
          key={index}
          value={value}
          starColor={starColor}
          starBackgroundColor={starBackgroundColor}
          lastElement={index === (starBackgroundColor ? 5 : full)}
        />
      ))}
    </div>
  );
};

export default StarRating;
