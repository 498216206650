import BackendRepository from '../api/interfaces/BackendRepository';
import BackendRepositoryRemote from '../api/BackendRepositoryRemote';
import { Signature, SignatureImageUploadParams, SignatureUpdateParams } from '../interfaces/Signature';

export default class SignatureService {
  private repository: BackendRepository;

  constructor() {
    this.repository = new BackendRepositoryRemote();
  }

  public async getSignature(ticketHashId: string): Promise<Signature> {
    return await this.repository.getSignature(ticketHashId);
  }

  public async updateSignature(params: SignatureUpdateParams ): Promise<void> {
    return await this.repository.updateSignature(params);
  }

  public async uploadImage(params: SignatureImageUploadParams): Promise<void> {
    return await this.repository.uploadImage(params);
  }
}
