import { FC } from 'react';
import classNames from 'classnames';

import style from './NoSlotExplanationItem.module.scss';

interface NoSlotExplanationItemProps {
  label: string;
  partnerColor?: boolean;
}


export const NoSlotExplanationItem: FC<NoSlotExplanationItemProps> = ({ label, partnerColor }) => {
  const buttonClassNames = classNames({
    [style.icon]: true,
    ['partner-border-color']: partnerColor,
  });

  return (
    <div className={style.root}>
      <div className={buttonClassNames}/>
      <p className={style.label}>{label}</p>
    </div>
  );
};
