import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import style from './NoSlotButton.module.scss';
import { handleKeyboardSelect } from '../../helpers/Accessibility';

interface NoSlotButtonProps {
  text: string;
  disabled?: boolean;
  selected?: boolean;
  onSelect: (selected: boolean) => void;
  className?: string;
}


export const NoSlotButton: FC<NoSlotButtonProps> = ({ text, selected, disabled, onSelect, className = '' }) => {
  const buttonClassNames = classNames({
    [style.button]: true,
    ['partner-border-color']: selected,
    [style.selected]: selected,
    [style.disabled]: disabled,
    [className]: !!className
  });

  const handleSelect = useCallback(() => {
    if (!disabled) {
      onSelect(!selected);
    }
  }, [onSelect, disabled, selected]);

  const handleKeyDown = useMemo(() => handleKeyboardSelect(handleSelect), [handleSelect]);

  return (
    <div
      role="button"
      className={buttonClassNames}
      onClick={handleSelect}
      tabIndex={!disabled ? 0 : -1}
      onKeyDown={handleKeyDown}
    >
      {text}
    </div>
  );
};
