import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import style from './TermsAndConditions.module.scss';
import { useFormatting } from '../../services/hooks/useFormatting';

interface TermsAndConditions {
  link: string;
  partner: string;
}

const TermsAndConditionsComponent: FC<TermsAndConditions> = ({ link, partner }) => {
  const { t } = useTranslation();
  const { formattedText: terms } = useFormatting(t('footer:terms', { partner, link }));

  return (
    <div className={style.terms}>
      {!!link && !!partner && <p className="col-sm-12 col-md-9 col-lg-4" dangerouslySetInnerHTML={{ __html: terms }} />}
      <p>
        <a rel="noopener noreferrer" target="_blank" href="https://www.guidion.com/privacy-cookie-statement">
          {t('footer:cookieStatement')}
        </a>
      </p>
    </div>
  );
};

export const TermsAndConditions = React.memo(TermsAndConditionsComponent);
