import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Faq.module.scss';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import FaqSections from './FaqSections';

import usePageTitle from '../../services/hooks/usePageTitle';
import { useScrollToTop } from '../../helpers/hooks/useScrollToTop';

const Faq: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);

  const { t } = useTranslation();

  usePageTitle(t('pageTitle:faq'));
  useScrollToTop();

  if (!stylesheet) {
    return null;
  }

  return (
    <div className={styles.faq}>
      {stylesheet.faq?.faqs.length ? (
        <FaqSections faqsSections={stylesheet.faq.faqs} />
      ) : (
        <h2>{t('faq:thereAreNoFaqAvailable')}</h2>
      )}
    </div>
  );
};

export default Faq;
