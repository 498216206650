export default {
  nl: {
    signature: {
      title: 'Vandaag geleverd',
      productsConsumed: 'Geleverde diensten en producten',
      productsReturned: 'Retourgenomen producten',
      total: 'Totaal',
      includingVat: 'incl. btw',
      signVisit: 'Teken hier voor je bezoek',
      activateSignature: 'Tik om te activeren',
      declineSign: 'Ik wil toch niet tekenen',
      customerInformation: 'Klant informatie',
      expertComment: 'Notitie',
      customerTerms: 'Klant handtekening',
      completedSignature: {
        accepted: {
          title: 'Je handtekening is verzonden!',
          description: 'Je bent akkoord gegaan met de werkbon. Deze zal ook per mail verzonden worden.'
        },
        declined: {
          title: 'Je hebt niet getekend',
          description: 'Je gaat hiermee niet akkoord met de werkbon.'
        },
      }
    },
  },
  en: {
    signature: {
      title: 'Provided today',
      productsConsumed: 'Provided services and products',
      productsReturned: 'Returned products',
      total: 'Total',
      includingVat: 'incl. VAT',
      signVisit: 'Sign here for the visit',
      activateSignature: 'Tap to activate',
      declineSign: "I don't want to sign anyway",
      customerInformation: 'Customer information',
      expertComment: 'Comment',
      customerTerms: 'Customer signature',
      completedSignature: {
        accepted: {
          title: 'Your signature has been sent!',
          description: 'You agreed with the work order. This will also be provided by email.'
        },
        declined: {
          title: 'You did not sign',
          description: 'Therefore you did not agree with the work order.'
        },
      }
    },
  }
};
