import { FC, forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from './NoSlotFormOld.module.scss';
import { AvailableNoSlots, SaveNoSlotParams } from '../../interfaces/AvailableNoSlots';
import { Textarea } from '../Form/FormInputs';
import { NoSlotExplanationItem } from './NoSlotExplanationItem';
import NoSlotSelectorsOld from './NoSlotSelectorsOld';
import Alert from '../Alert/Alert';
import Button from '../Button';

import { sendAnalyticsNoSlots } from '../../services/Analytics';
import { getNumberOfSelectedDays } from '../../services/TopSlotService';
import TicketService from '../../services/TicketService';


interface NoSlotFormOldProps {
  hashId: string;
  workorderId: string;
  onComplete: () => void;
}

const getInitialNoSlotState = (): AvailableNoSlots => ({
  monday: { morning: false, afternoon: false },
  tuesday: { morning: false, afternoon: false },
  wednesday: { morning: false, afternoon: false },
  thursday: { morning: false, afternoon: false },
  friday: { morning: false, afternoon: false },
  saturday: { morning: false, afternoon: false },
  sunday: { morning: false, afternoon: false },
});

const NoSlotFormOld: FC<NoSlotFormOldProps> = ({ hashId, workorderId, onComplete }) => {
  const [slots, setSlots] = useState(getInitialNoSlotState());
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const TextAreaComponent = forwardRef(Textarea);

  const saveNoTopslots = async (): Promise<void> => {
    const params: SaveNoSlotParams = {
      version: 'old',
      comment: textAreaRef.current?.value || t('noslotsOld:nocomment')!,
      slots,
      confirmationType: 'AUTO'
    };

    try {
      setSaving(true);
      await new TicketService().saveNoSlotInformation(hashId, workorderId, params);

      sendAnalyticsNoSlots(params);

      onComplete();
    } catch (error) {
      setError(true);
      setSaving(false);
      console.error(error);
    }
  };

  const selectedSlotsNumber = getNumberOfSelectedDays(slots);

  return (
    <div>
      <NoSlotSelectorsOld slots={slots} updateSlots={setSlots} />
      <div className={style.selectorsExplanation}>
        <NoSlotExplanationItem label={t('noslotsOld:available')} partnerColor />
        <NoSlotExplanationItem label={t('noslotsOld:notAvailable')} />
      </div>
      <div className={style.extraInfoRoot}>
        <div>
          <h2 className={style.sectionHeaderComment}>{t('noslotsOld:extrainfo')}</h2>
          <form className={style.commentContainer}>
            <TextAreaComponent id="comment" className="" name="comment" ref={textAreaRef}/>
            <label htmlFor="comment" className={style.commentLabel}>{t('noslotsOld:comment')}</label>
          </form>
        </div>
        <div>
          <h2 className={style.sectionHeader}>{t('noslotsOld:autoSchedule')}</h2>
          <p className={style.autoExplanation}>{t('noslotsOld:autoScheduleExplanation')}</p>
          {!!error && (
            <div className={style.errorMessage} >
              <Alert
                icon= "error"
                text={t('alert:somethingWentWrong')}/>
            </div>
          )}
          <div className={style.btnsSection}>
            <Button
              disabled={saving || selectedSlotsNumber < 3}
              onClick={(): Promise<void> => saveNoTopslots()}
              text={t('noslotsOld:scheduleAutoBtn')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoSlotFormOld;
