export default {
  nl: {
    question: {
      title: 'Vragen beantwoorden',
      goBack: 'Terug naar gegevens',
      loadingQuestions: 'De vragen worden geladen ...',
      savingAnswers: 'De antwoorden worden opgeslagen ...',
    }
  },
  en: {
    question: {
      title: 'Answer questions',
      goBack: 'Back to details',
      loadingQuestions: 'Loading questions ...',
      savingAnswers: 'Saving answers...',
    }
  }
};
