import { FC, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { StylesheetContext } from '../../contexts/StylesheetContext';
import { TicketContext } from '../../contexts/TicketContext';
import { sendConfirmShown24Hours } from '../../services/Analytics';
import { useFormatting } from '../../services/hooks/useFormatting';
import usePageTitle from '../../services/hooks/usePageTitle';
import DateTime from '../../helpers/DateTime';
import ExpertCard from './ExpertCard/ExpertCard';
import ConfirmReschedule from './ConfirmReschedule';
import CardItem from '../../components/Card/CardItem';
import Card from '../../components/Card/Card';
import NoSlotsSummary from '../../components/NoSlots/NoSlotsSummary';
import FeedbackWidget from '../../components/Typeform/FeedbackWidget';

import styles from './Confirm.module.scss';
import Calendar from './Calendar/Calendar';
import { isTicketCancellable } from '../../helpers/TicketHelpers';
import { NoTopSlot } from '../../interfaces/NoTopSlot';


const Confirm: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);
  const { ticket, showFeedback, fetchTicket } = useContext(TicketContext);
  const { state } = useLocation();
  const { t } = useTranslation();
  const selectedNoTopSlots: NoTopSlot[] | undefined = useMemo(() => state?.selectedNoTopSlots, [state]);

  usePageTitle(t('pageTitle:confirm'));

  const within24hours = ticket?.workOrder.appointment?.arrivalWindowStart ?
    DateTime.withinDayBefore(ticket.workOrder.appointment.arrivalWindowStart) : false;

  useEffect(() => {
    fetchTicket();
    if (within24hours) {
      sendConfirmShown24Hours();
    }
  }, [fetchTicket, within24hours]);


  const appointmentRange = ticket ? {
    start: ticket.workOrder.appointment.arrivalWindowStart!,
    finish: ticket.workOrder.appointment.arrivalWindowEnd!
  } : undefined;

  const { formattedText: appointmentTitle } = useFormatting(t('confirmSection:confirmedAppointment'), {
    replaceDate: ticket?.workOrder.appointment.arrivalWindowStart || null,
    range: appointmentRange,
  });

  const appointmentDurationRange = ticket ? {
    start: ticket.workOrder.appointment.scheduledStart!,
    finish: ticket.workOrder.appointment.scheduledEnd!
  } : undefined;

  const { formattedText: averageTime } = useFormatting(t('confirmSection:averageVisit'), {
    replaceDate: ticket?.workOrder.appointment.arrivalWindowStart || null,
    range: appointmentDurationRange,
    roundTo: 5
  });

  const { formattedText: warningText } = useFormatting(stylesheet?.confirmationSection.warningText);
  const shouldDisplaySelectedNoTopSlots = !!selectedNoTopSlots?.length && ticket?.abTesting === 'no-topslots';

  const title: string = useMemo(() => {
    if (shouldDisplaySelectedNoTopSlots) {
      return t('confirmSection:selectedNoTopSlotsContentTitle');
    }

    switch (ticket?.workOrder.appointment.status) {
      case 'On Route':
        return t('confirmSection:expertOnWay');
      case 'Completed':
      case 'Cannot Complete':
      case 'In Progress':
        return t('confirmSection:expertPassedBy');
      default:
        return appointmentTitle;
    }
  }, [ticket, appointmentTitle, t, shouldDisplaySelectedNoTopSlots]);

  if (!stylesheet || !ticket || !stylesheet.confirmationSection) {
    return null;
  }

  const feedbackQuestionsId = stylesheet.settings.feedbackQuestions?.id;
  const serviceAppointmentId = ticket.workOrder.appointment?.id || '';
  const appointment = ticket.workOrder.appointment;
  const moreThan24HoursBefore = appointment.arrivalWindowStart ?
    DateTime.moreThanDayBefore(appointment.arrivalWindowStart) : false;

  const lessThan24HoursAfter = appointment.scheduledEnd ? DateTime.lessThanDayAfter(appointment.scheduledEnd) : false;

  const reschedulingEnabled = ticket.workOrder.appointment.allowRescheduling;
  const cancelingEnabled = isTicketCancellable(ticket, stylesheet);

  return (
    <div className={styles.ticketConfirm}>
      <h1>{title}</h1>
      {shouldDisplaySelectedNoTopSlots && (
        <p>{t('confirmSection:selectedNoTopSlotsDescription')}</p>
      )}

      {appointment.status === 'Dispatched' && (
        <Calendar appointment={appointment} partnerName={stylesheet.settings.partnerName} text={t('confirmSection:agenda')} />
      )}

      {!!(reschedulingEnabled || cancelingEnabled) && (
        <ConfirmReschedule cancelingEnabled={cancelingEnabled} reschedulingEnabled={reschedulingEnabled} />
      )}

      {shouldDisplaySelectedNoTopSlots && (
        <NoSlotsSummary selectedNoTopSlots={selectedNoTopSlots} />
      )}

      {!!lessThan24HoursAfter && (
        <>
          <p className={styles.title}>{t('confirmSection:aboutYourVisit')}</p>
          <Card>
            <CardItem icon="access_time" classStyle={styles.item}>
              <p dangerouslySetInnerHTML={{ __html: averageTime }} />
            </CardItem>
          </Card>
        </>
      )}

      {!!showFeedback && (
        <FeedbackWidget feedbackQuestionsId={feedbackQuestionsId} serviceAppointmentId={serviceAppointmentId} />
      )}

      <div className={styles.extraInfoContainer}>
        {!!within24hours && !!appointment.expertId && !!lessThan24HoursAfter && (
          <div className={styles.visitcardContainer}>
            <p className={styles.title}>{t('confirmSection:whoWasThere')}</p>
            <ExpertCard ticketId={ticket.hashId} />
          </div>
        )}

        {!!moreThan24HoursBefore && (
          <Card classStyle={styles.warningContainer}>
            <i className={classNames('material-icons', 'partner-font-color', styles.itemIcon)}>{'info'}</i>
            <div className={styles.warningText}>
              <p className={styles.warningTitle}>{stylesheet.confirmationSection.contentTitle}</p>
              <p dangerouslySetInnerHTML={{ __html: warningText }} />
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Confirm;
