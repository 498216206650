export default {
  nl: {
    form: {
      personalDetails: 'Persoonsgegevens',
      initials: 'Initialen',
      middleName: 'Tussenvoegsel',
      lastName: 'Achternaam',
      phoneNumber: 'Telefoonnummer',
      mobileNumber: 'Mobiel nummer',
      email: 'E-mailadres',
      addressDetails: 'Adresgegevens',
      street: 'Straatnaam',
      zipCode: 'Postcode',
      city: 'Woonplaats',
      question: 'Vraag',
      correctDetails: 'Mijn gegevens zijn correct',
      requiredField: 'Veld is verplicht',
    }
  },
  en: {
    form: {
      personalDetails: 'Personal details',
      initials: 'Initials',
      middleName: 'Middle name',
      lastName: 'Lastname',
      phoneNumber: 'Phone number',
      mobileNumber: 'Mobile number',
      email: 'E-mail',
      addressDetails: 'Address details',
      street: 'Street',
      zipCode: 'Postal code',
      city: 'City',
      question: 'Your question',
      correctDetails: 'My details are correct',
      requiredField: 'Required field',
    }
  }
};
