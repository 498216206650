import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import style from './DateButton.module.scss';
import DateTime from '../../helpers/DateTime';
import { handleKeyboardSelect } from '../../helpers/Accessibility';

interface DateButtonProps {
  slot: string;
  selected?: boolean;
  disabled?: boolean;
  onSelect: (slot: string) => void;
}

export const DateButton: FC<DateButtonProps> = ({ slot, selected, disabled, onSelect }) => {

  const buttonClassNames = classNames({
    [style.btnDate]: true,
    ['partner-border-color']: selected && !disabled,
    [style.btnDateDisabled]: disabled,
  });

  const week = `${DateTime.formatDate('EEEEEE', new Date(slot))} `;
  const day = `${DateTime.formatDate('d MMM', new Date(slot))} `;

  const onClick = useCallback(() => {
    if (disabled) return;
    onSelect(slot);
  }, [onSelect, disabled, slot]);

  const handleKeyDown = useMemo(() => handleKeyboardSelect(onClick), [onClick]);

  return (
    <button
      className={buttonClassNames}
      onClick={onClick}
      tabIndex={!disabled ? 0 : undefined}
      onKeyDown={handleKeyDown}
      aria-label={DateTime.formatDate('EEEE, do MMMM', new Date(slot))}
      aria-disabled={disabled}
      aria-current={selected ? 'date' : false}
    >
      <span>{week}</span>
      <span>{day}</span>
    </button>
  );
};
