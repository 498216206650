import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stylesheet } from '../../interfaces/Stylesheet';
import Alert from '../../components/Alert/Alert';
import ContainerHeader from '../../components/ContainerHeader/ContainerHeader';

interface WelcomeProps {
  stylesheet: Stylesheet;
  allowCancelling: boolean;
}

const CustomerHeader: FC<WelcomeProps> = ({ stylesheet, allowCancelling }) => {
  const { t } = useTranslation();

  return (
    <ContainerHeader
      title={t('customerSection:title', { partner: stylesheet.settings.partnerName })}
      text={stylesheet.customerSection.leadBlock.value}
    >
      {
        allowCancelling ? (
          <Alert
            text={t('alert:cancelTicket')}
            icon="error_outline"
          />
        ) : null
      }
    </ContainerHeader>
  );
};

export default CustomerHeader;
