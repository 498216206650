export default {
  nl: {
    alert: {
      saveSuccess: 'De gegevens zijn bijgewerkt.',
      savingDetails: 'Gegevens bijwerken ...',
      saveError: 'De gegevens konden niet worden opgeslagen.',
      cancelTicket: 'Toch geen hulp van onze expert meer nodig? [a="/c/:ticketId"]Klik dan hier om het verzoek te annuleren.[/a]',
      bookingError: 'Er is iets fout gegaan. De afspraak kan niet worden opgeslagen.',
      somethingWentWrong: 'Er is iets misgegaan. Probeer het opnieuw',
    }
  },
  en: {
    alert: {
      saveSuccess: 'The details have been updated.',
      savingDetails: 'Updating details ...',
      saveError: 'The details could not be saved.',
      cancelTicket: 'Do you no longer need a visit from our expert? [a=\"/c/:ticketId\"]Click here to cancel the visit.[/a]',
      bookingError: 'Something went wrong. The appointment cannot be saved.',
      somethingWentWrong: 'Something went wrong. Please try again.',
    }
  }
};
