import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';
import roundToNearestMinutesFNS from 'date-fns/roundToNearestMinutes';
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-GB';

import Language from './Language';

const formatDate = (dateFormat: string, date: Date): string => {
  const locale = Language.getLanguage() === 'en' ? en : nl;

  return format(date, dateFormat, { locale });
};

const getTimeStringFromDate = (date: Date): string => {
  return formatDate('HH:mm', date);
};

const today = (): Date => new Date(Date.now());

const differenceInDays = (date1: Date, date2: Date): number => {
  return differenceInCalendarDays(date1, date2);
};

const getDateXDaysInTheFuture = (amountOfDays: number): Date => {
  const date = today();
  date.setDate(date.getDate() + amountOfDays);

  return date;
};

const setDateToStartOfTheDay = (date: Date): Date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

const rangeDays = (from: Date, to: Date): string[] => {
  const range = [];
  let newDay: Date = new Date(from);

  while (newDay < to) {
    range.push(format(newDay, 'yyyy-MM-dd'));
    newDay = new Date(newDay.setDate(newDay.getDate() + 1));
  }

  return range;
};

const cloneDate = (date: Date): Date => {
  return new Date(date.getTime());
};

/**
 * @param time Time in format of "17:30"
 */
const dateFromTime = (time: string): Date => {
  return parse(time, 'HH:mm', new Date());
};

const getDurationRounded = (from: Date, to: Date, currentLocale: string, roundDurationTo: number | undefined = 15): string => {
  const locale = currentLocale === 'en' ? en : nl;
  const duration = intervalToDuration({ start: from, end: to });

  const durationInMinutes = duration.minutes! + duration.hours! * 60;
  const durationInMinutesRounded = Math.round(durationInMinutes / roundDurationTo) * roundDurationTo;

  return formatDuration({ minutes: durationInMinutesRounded % 60, hours: Math.trunc(durationInMinutesRounded / 60) }, { locale });
};

const withinDayBefore = (startTime: string): boolean => {
  const appointmentDate = new Date(startTime);
  const appointmentDateDayBefore = addDays(appointmentDate, -1);

  const now = new Date();

  return !isBefore(now, appointmentDateDayBefore);
};

const moreThanDayBefore = (startTime: string): boolean => {
  const appointmentDate = new Date(startTime);
  const appointmentDateDayBefore = addDays(appointmentDate, -1);

  const now = new Date();

  return isBefore(now, appointmentDateDayBefore);
};

const lessThanDayAfter = (endTime: string): boolean => {
  const appointmentDate = new Date(endTime);
  const appointmentDateDayAfter = addDays(appointmentDate, 1);

  const now = new Date();

  return isBefore(now, appointmentDateDayAfter);
};

/**
 * @param minutes must be between 1 and 30
 */
const roundToNearestMinutes = (date: string | Date, minutes?: number): Date => {
  return roundToNearestMinutesFNS(new Date(date), { nearestTo: minutes || 5 });
};

export default {
  formatDate, getTimeStringFromDate, today, differenceInDays, getDateXDaysInTheFuture, setDateToStartOfTheDay,
  rangeDays, cloneDate, dateFromTime, getDurationRounded, lessThanDayAfter, moreThanDayBefore, withinDayBefore,
  roundToNearestMinutes
};
