import { StepId, StepModel } from '../components/Steps/interfaces/Step';
import { Ticket } from '../interfaces/Ticket';

const getCustomerStep = (isAtConfirmation: boolean, isAtScheduling: boolean): StepModel => ({
  id: StepId.customer,
  isDisabled: isAtConfirmation,
  isCompleted: isAtScheduling || isAtConfirmation
});

const getQuestionsStep = (isAtConfirmation: boolean, isAtScheduling: boolean): StepModel => ({
  id: StepId.questions,
  isDisabled: !isAtScheduling,
  isCompleted: isAtScheduling || isAtConfirmation
});

const getAppointmentStep = (isNewTicket: boolean, isAtConfirmation: boolean): StepModel => ({
  id: StepId.appointment,
  isDisabled: isNewTicket || isAtConfirmation,
  isCompleted: isAtConfirmation
});

const getChangeStep = (isAtConfirmation: boolean): StepModel => ({
  id: StepId.change,
  isDisabled: isAtConfirmation,
  isCompleted: isAtConfirmation
});

const getConfirmStep = (isAtConfirmation: boolean): StepModel => ({
  id: StepId.confirm,
  isDisabled: !isAtConfirmation,
  isCompleted: isAtConfirmation
});

const getDefaultSteps = (ticket?: Ticket, isInErrorMode?: boolean): StepModel[] => {

  if (isInErrorMode) {
    return [
      getCustomerStep(false, false),
      getQuestionsStep(false, false),
      getAppointmentStep(false, false),
      getConfirmStep(false)
    ].map(step => ({ ...step, isDisabled: true, isCompleted: false }));
  }

  if (!ticket) return [];

  const { workOrder } = ticket;
  const { isQuestionnaireCompleted, status: workOrderStatus } = workOrder;
  const { status, isSchedulable, arrivalWindowStart, allowRescheduling } = workOrder.appointment;
  const blocked = !isSchedulable;

  const isNewTicket = ((workOrderStatus === 'New') || !workOrderStatus) && (!isQuestionnaireCompleted || blocked);

  const confirmationStatuses = ['Completed', 'In Progress', 'On Route', 'Dispatched', 'Cannot Complete'];
  const isAtScheduling = !isNewTicket && (!arrivalWindowStart && !!isQuestionnaireCompleted)
    || (!!arrivalWindowStart && !confirmationStatuses.includes(status));

  const isAtConfirmation = (!isNewTicket && !isAtScheduling) || confirmationStatuses.includes(status);

  const relevantSteps = [
    getCustomerStep(isAtConfirmation, isAtScheduling),
    getQuestionsStep(isAtConfirmation, isAtScheduling)
  ];

  if (allowRescheduling) {
    relevantSteps.push(getChangeStep(isAtConfirmation));
  } else {
    relevantSteps.push(getAppointmentStep(isNewTicket, isAtConfirmation));
  }

  relevantSteps.push(getConfirmStep(isAtConfirmation));

  return relevantSteps.filter(item => item.id);
};

const getActiveStep = (steps: StepModel[]): StepModel => {
  const uncompletedSteps = steps.filter(step => !step.isCompleted);
  const areAllCompleted = uncompletedSteps.length === 0;
  const lastStep = steps[steps.length - 1];
  const activeStep = areAllCompleted ? lastStep : steps.find(step => !step.isDisabled && !step.isCompleted);

  return activeStep || steps[0];
};

export default {
  getDefaultSteps,
  getActiveStep
};
