import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translations from '../translations';
import { EnabledLanguage } from '../interfaces/Language';

export const defaultLanguage: EnabledLanguage = 'nl';

export const defaultNS = 'translation';

const previouslySelectedLanguage = localStorage?.getItem('i18nextLng');

const initialLanguage = previouslySelectedLanguage || defaultLanguage;

i18n.use(
  LanguageDetector
).use(
  initReactI18next
).init({
  resources: translations,
  lng: initialLanguage,
  fallbackLng: defaultLanguage,

  keySeparator: ':',
});

const setHtmlLang = (lng: string) => {
  document.documentElement.setAttribute('lang', lng);
};

i18n.on('languageChanged', setHtmlLang);
setHtmlLang(initialLanguage);

export default i18n;
