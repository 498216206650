import { DayType } from '../interfaces/AvailableNoSlots';

export default {
  nl: {
    shared: {
      agree: 'Akkoord'
    },
    days: {
      monday: 'Maandag',
      tuesday: 'Dinsdag',
      wednesday: 'Woensdag',
      thursday: 'Donderdag',
      friday: 'Vrijdag',
      saturday: 'Zaterdag',
      sunday: 'Zondag',
    } as Record<DayType, string>,
    daysShort: {
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
    },
    timeofday: {
      morning: 'Ochtend',
      afternoon: 'Middag',
    },
    chat: {
      loading: 'Laden',
      employeeAvailableText: 'Chat',
      employeeNotAvailableText: 'Medewerker offline',
      firstNameFieldLabel: 'Voornaam',
      lastNameFieldLabel: 'Achternaam'
    },
    technicianPassport: {
      default: 'uit'
    },
    stepsBar: {
      appointment: 'Afspraak',
      change: 'Wijzigen',
      confirm: 'Bevestiging',
      customer: 'Gegevens',
      questions: 'Vragen',
    },
    pageTitle: {
      customer: 'Persoonlijke gegevens',
      questions: 'Vragen',
      appointment: 'Afspraak selecteren',
      confirm: 'Afspraakbevestiging',
      change: 'Wijzig afspraak',
      faq: 'Veel gestelde vragen',
      contact: 'Contact',
      cancel: 'Afspraak annuleren',
      error: 'Foutmelding',
      signature: 'Handtekening'
    },
    language: {
      selector: 'Selecteer taal',
    },
  },
  en: {
    shared: {
      agree: 'Agree'
    },
    days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    } as Record<DayType, string>,
    daysShort: {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    },

    timeofday: {
      morning: 'Morning',
      afternoon: 'Afternoon',
    },
    chat: {
      loading: 'Loading',
      employeeAvailableText: 'Chat',
      employeeNotAvailableText: 'Agent offline',
      firstNameFieldLabel: 'First Name',
      lastNameFieldLabel: 'Last Name'
    },
    technicianPassport: {
      default: 'from'
    },
    stepsBar: {
      appointment: 'Appointment',
      change: 'Change',
      confirm: 'Confirm',
      customer: 'Details',
      questions: 'Questions',
    },
    pageTitle: {
      customer: 'Customer information',
      questions: 'Questions',
      appointment: 'Appointment selection',
      confirm: 'Confirmation',
      change: 'Change appointment',
      faq: 'FAQ',
      contact: 'Contact',
      cancel: 'Cancel appointment',
      error: 'Error',
      signature: 'Signature'
    },
    language: {
      selector: 'Language selector',
    },
  }
};
