import { FunctionComponent } from 'react';

import FaqsService from '../../services/FaqsService';
import { Faq } from '../../interfaces/Stylesheet';
import { useFormatting } from '../../services/hooks/useFormatting';

import styles from './Faq.module.scss';

interface FaqProps {
  faq: Faq;
  searchText?: string;
}

const FaqItemComponent: FunctionComponent<FaqProps> = ({ faq, searchText }) => {
  const { formattedText: title } = useFormatting(FaqsService.highlightText(faq.title, searchText));
  const { formattedText: text } = useFormatting(FaqsService.highlightText(faq.text, searchText));

  return (
    <li className={styles.faqListItem}>
      {!!faq.title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
      {!!faq.text && <p dangerouslySetInnerHTML={{ __html: text }} />}
    </li>
  );
};

export default FaqItemComponent;
