import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import style from './WhatsAppLink.module.scss';
import { TicketContext } from '../../contexts/TicketContext';


interface WhatsAppLinkProps {
  contactNumber: string;
}

const WhatsAppLink: FC<WhatsAppLinkProps> = ({ contactNumber }): JSX.Element | null => {
  const { t } = useTranslation();
  const { ticket } = useContext(TicketContext);

  if (!ticket) {
    return null;
  }

  const emptySpaceCondition = /\s/g;

  // In case there is a misconfigurion on backend this removes spaces between numbers if present
  const formattedContactNumber = contactNumber.replace(emptySpaceCondition, '');

  const formattedAddress = ticket.customer ? `${ticket.customer.address.postalCode} ${
    // This regex keep only street number from the string. Example: James Wattstraat 77-M Output: 77-M
    ticket.customer.address.street.replace(/^[^\d]*?(\d.*)?$/, '$1')
  }` : '';

  const formatedPrefilledText = formattedAddress ? t('contact:whatsappPrefilledText', {
    address: formattedAddress
  }).replace(emptySpaceCondition, '%20') : '';

  // This regex formats numbers from +31XXXXXXXXX to +31 XX XXX XXXX
  const formattedPhoneNumber = formattedContactNumber.replace(/^(\+31)(6[\d]{1}|[\d]{2})([\d]{3})([\d]{4})$/, '$1 $2 $3 $4');


  return (
    <a
      aria-label={t('contact:chatWithWhatsApp')}
      href={`https://wa.me/${formattedContactNumber}?text=${formatedPrefilledText}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span className={style.container}>
        <img alt="whatsapp" src="/assets/whatsapp-16d5c94fe568849360d043e00bce1262.svg" />
        {formattedPhoneNumber}
      </span>
    </a>
  );
};

export default WhatsAppLink;
