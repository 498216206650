import { createContext, useCallback, useState } from 'react';

import { MultiLingualStylesheet, Stylesheet } from '../interfaces/Stylesheet';
import Language from '../helpers/Language';
import { EnabledLanguage } from '../interfaces/Language';
import { FCWithChild } from '../interfaces/Shared';

export interface StylesheetProviderStore {
  stylesheet: Stylesheet | undefined;
  changeStylesheetLanguage: (lng: EnabledLanguage) => void;
  setStylesheets: (globalStylesheet: MultiLingualStylesheet, brandStylesheet?: MultiLingualStylesheet) => void;
}

export const StylesheetContext = createContext({} as StylesheetProviderStore);

const updateFavicon = (faviconUrl: string): void => {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = `${faviconUrl}?=${Math.random()}`;

  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};

const StylesheetProvider: FCWithChild = ({ children }) => {
  const [multilingualGlobalStylesheet, setMultilingualGlobalStylesheet] = useState<MultiLingualStylesheet | undefined>(undefined);
  const [stylesheet, setUsedStylesheet] = useState<Stylesheet | undefined>(undefined);
  const [multilingualStylesheet, setMultilingualBrandStylesheet] = useState<MultiLingualStylesheet | undefined>(undefined);

  const changeStylesheetLanguage = (language: EnabledLanguage): void => {
    applyStylesheet(multilingualGlobalStylesheet!, multilingualStylesheet, language);
  };

  const applyStylesheet = useCallback((
    globalStylesheet: MultiLingualStylesheet,
    brandStylesheet: MultiLingualStylesheet | undefined,
    language: EnabledLanguage
  ) => {
    const stylesheetWithTranslation = brandStylesheet?.[language] || globalStylesheet?.[language];

    if (!stylesheetWithTranslation) {
      return;
    }

    // Must be done before changing state because it must remove the old reference
    if (stylesheet?.settings.cssReference) {
      document.body.classList.remove(stylesheet.settings.cssReference)
    }

    setUsedStylesheet(stylesheetWithTranslation);

    document.title = stylesheetWithTranslation.settings.pageTitle;
    updateFavicon(stylesheetWithTranslation.settings.faviconUrl);
    document.body.classList.add(stylesheetWithTranslation.settings.cssReference);
  }, [stylesheet]);

  const setStylesheets = (
    globalStylesheet: MultiLingualStylesheet,
    brandStylesheet?: MultiLingualStylesheet
  ) => {
    setMultilingualGlobalStylesheet(globalStylesheet);
    if (brandStylesheet) {
      setMultilingualBrandStylesheet(brandStylesheet);
    }
    applyStylesheet(globalStylesheet, brandStylesheet, Language.getLanguage())
  }

  const store: StylesheetProviderStore = {
    stylesheet,
    changeStylesheetLanguage,
    setStylesheets
  };

  return (
    <StylesheetContext.Provider value={store}>
      {children}
    </StylesheetContext.Provider>
  );
};

export { StylesheetProvider };

export const StylesheetConsumer = StylesheetContext.Consumer;
