import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import style from './Backdrop.module.scss';

const BACKDROP_WRAPPER_ID = 'backdrop-parent';

export interface BackdropProps {
  onClick: () => void,
  visible: boolean
}

export const Backdrop: FC<BackdropProps> = ({ onClick, visible }) => {
  const [parentElement, setParentElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    let parent: HTMLElement | null = getBackdropParent();

    if (!parent) {
      parent = createBackdropParent();
    }

    setParentElement(parent);
  }, []);

  const getBackdropParent = (): HTMLElement | null => {
    return document.getElementById(BACKDROP_WRAPPER_ID);
  };

  const createBackdropParent = (): HTMLDivElement => {
    const body: HTMLBodyElement = document.querySelector('body')!;
    const div = document.createElement('div');
    div.setAttribute('id', BACKDROP_WRAPPER_ID);

    body.append(div);
    return div;
  };

  const Backdrop = (
    <button
      data-test-id="backdrop-button"
      className={style.backdrop}
      onClick={onClick}
      onTouchStart={onClick}
    />
  );

  return visible && !!parentElement ?
    createPortal(Backdrop, parentElement)
    : null;
};

export default Backdrop;
