import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import BlockBG from '../../components/BlockBG/BlockBG';
import WhatsAppLink from '../../components/WhatsAppLink/WhatsAppLink';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { useFormatting } from '../../services/hooks/useFormatting';

interface ContactSidebarProps {
  stylesheet: Stylesheet;
}

const ContactSidebar: FunctionComponent<ContactSidebarProps> = ({ stylesheet }) => {
  const { formattedText: openingHours } = useFormatting(stylesheet.contact.openingHoursBlock.value);
  const { serviceDeskNumber, whatsappNumber } = stylesheet.contact;
  const { t } = useTranslation();

  return (
    <div className="col col-sm-12 col-md-12 col-lg-5 sidebar">
      <BlockBG>
        <h2 className="primary-color">{t('contact:phoneTitle')}</h2>
        <p>
          <a href={`tel:${serviceDeskNumber}`}>{serviceDeskNumber}</a> ({t('contact:localTariff')})
        </p>
        {!!whatsappNumber && (
          <>
            <p>{t('contact:chatWithWhatsApp')}</p>
            <WhatsAppLink contactNumber={whatsappNumber} />
          </>
        )}
        <p dangerouslySetInnerHTML={{ __html: openingHours }} />
      </BlockBG>
    </div>
  );
};

export default ContactSidebar;
