import { FunctionComponent } from 'react';

import styles from './Thanks.module.scss';

const ThanksImage: FunctionComponent = () => (
  <svg className={styles.noSlotsThankYouImage} width="316" height="316" viewBox="0 0 316 316" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <rect x="28" y="4" width="260" height="260" rx="88" fill="#E9EEF2"/>
    </g>
    <rect x="38" y="14" width="240" height="240" rx="84" fill="white"/>
    <g clipPath="url(#clip0)">
      <path d="M96.125 223.985C80.615 223.985 68 211.37 68 195.86V184.61C68 181.505 70.52 178.985 73.625 178.985H79.25V105.86C79.25 96.5525 86.8175 88.985 96.125 88.985H124.25C127.355 88.985 129.875 91.505 129.875 94.61C129.875 97.715 127.355 100.235 124.25 100.235H96.125C93.02 100.235 90.5 102.762 90.5 105.86V178.985H138.62C140.638 178.985 142.505 180.072 143.503 181.82C144.98 184.407 147.132 186.552 149.713 188.03C152.225 189.47 155.082 190.227 157.963 190.227C163.963 190.227 169.528 187.002 172.49 181.82C173.495 180.072 175.363 178.985 177.372 178.985H225.5V162.11C225.5 159.005 228.02 156.485 231.125 156.485C234.23 156.485 236.75 159.005 236.75 162.11V178.985H242.375C245.48 178.985 248 181.505 248 184.61V195.86C248 211.37 235.385 223.985 219.875 223.985H96.125ZM79.25 195.86C79.25 205.167 86.8175 212.735 96.125 212.735H219.875C229.182 212.735 236.75 205.167 236.75 195.86V190.235H180.41C175.152 197.3 166.873 201.477 157.955 201.477C153.125 201.477 148.348 200.21 144.132 197.802C140.81 195.905 137.878 193.302 135.59 190.235H79.25V195.86Z" fill="#BAC3CC"/>
      <path className="partner-fill" d="M135.598 154.61C133.91 154.61 132.328 153.867 131.263 152.562C129.853 150.86 129.575 148.482 130.558 146.495L144.875 117.425C140.293 106.19 139.678 93.6725 143.165 82.0175C147.245 68.3675 156.395 57.1175 168.935 50.345C176.728 46.1375 185.465 43.9175 194.218 43.9175C199.31 43.9175 204.403 44.6525 209.345 46.1075C209.345 46.1075 209.45 46.13 209.555 46.16C213.373 47.285 217.093 48.845 220.595 50.81C246.343 65.2325 255.56 97.9175 241.137 123.665C234.155 136.137 222.725 145.145 208.963 149.022C204.185 150.372 199.272 151.055 194.36 151.055C186.087 151.055 177.815 149.075 170.36 145.31L137.098 154.4C136.588 154.55 136.093 154.61 135.598 154.61ZM194.21 55.175C187.317 55.175 180.425 56.93 174.275 60.2525C164.375 65.5925 157.153 74.4725 153.935 85.2425C150.973 95.1725 151.76 105.8 156.163 115.182C156.89 116.735 156.875 118.512 156.118 120.057L146.158 140.277L169.528 133.895C170.023 133.76 170.518 133.7 171.013 133.7C171.972 133.7 172.925 133.947 173.765 134.42C180.08 137.96 187.198 139.827 194.353 139.827C198.23 139.827 202.115 139.287 205.895 138.222C216.762 135.162 225.785 128.045 231.305 118.19C242.698 97.85 235.415 72.035 215.075 60.6425C212.398 59.1425 209.548 57.9275 206.592 57.0425C206.495 57.02 206.398 56.99 206.3 56.96C202.363 55.775 198.29 55.175 194.21 55.175Z" fill="#E10074"/>
      <path className="partner-fill" d="M195.867 128.412C195.432 128.412 194.997 128.397 194.562 128.375C194.127 128.397 193.7 128.412 193.272 128.412C180.837 128.412 170.293 118.715 169.273 106.332C169.153 104.832 169.618 103.377 170.585 102.23C171.553 101.082 172.917 100.385 174.41 100.257C174.567 100.242 174.718 100.242 174.875 100.242C177.778 100.242 180.238 102.507 180.485 105.402C181.033 112.002 186.635 117.17 193.235 117.17C193.542 117.17 193.85 117.162 194.157 117.132C194.3 117.125 194.427 117.117 194.562 117.117C194.697 117.117 194.825 117.125 194.952 117.132C195.252 117.155 195.552 117.162 195.845 117.162C202.475 117.162 208.092 111.995 208.64 105.402C208.88 102.507 211.34 100.242 214.242 100.242C214.4 100.242 214.557 100.25 214.715 100.265C216.207 100.385 217.572 101.09 218.54 102.237C219.515 103.385 219.98 104.84 219.852 106.34C218.832 118.707 208.295 128.412 195.867 128.412Z" fill="#E10074"/>
      <path className="partner-fill" d="M211.438 88.985C216.097 88.985 219.875 85.2074 219.875 80.5475C219.875 75.8876 216.097 72.11 211.438 72.11C206.778 72.11 203 75.8876 203 80.5475C203 85.2074 206.778 88.985 211.438 88.985Z" fill="#E10074"/>
      <path className="partner-fill" d="M177.688 88.985C173.037 88.985 169.25 85.1975 169.25 80.5475C169.25 76.205 172.527 72.605 176.87 72.1775C177.192 72.1325 177.44 72.11 177.688 72.11C177.778 72.11 177.89 72.1175 177.995 72.125C178.085 72.1325 178.175 72.14 178.265 72.155C182.6 72.365 186.125 76.0625 186.125 80.5475C186.125 85.205 182.337 88.985 177.688 88.985Z" fill="#E10074"/>
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="316" height="316" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feMorphology radius="16" operator="erode" in="SourceAlpha" result="effect1_dropShadow"/>
        <feOffset dy="24"/>
        <feGaussianBlur stdDeviation="22"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <clipPath id="clip0">
        <rect width="180" height="180" fill="white" transform="translate(68 44)"/>
      </clipPath>
    </defs>
  </svg>
);

export default ThanksImage;
