import { FC } from 'react';

import styles from './CheckBoxInput.module.scss';
import classNames from 'classnames';

interface CheckBoxInputProps {
  id: string;
  label: string
  disabled?: boolean;
  isChecked: boolean;
  smallLabel?: boolean;
  onCheck: (isChecked: boolean) => void;
  className?: string;
}

const CheckBoxInput: FC<CheckBoxInputProps> = ({ id, label, isChecked, disabled, smallLabel, onCheck, className = '' }) => {
  const labelStyle = classNames({
    [styles.smallLabel]: !!smallLabel,
    [styles.disabled]: disabled,
  });

  const wrapperStyle = classNames({
    [styles.inputField]: true,
    [className]: !!className
  });

  return (
    <div key={label} className={wrapperStyle}>
      <input
        name={id}
        disabled={disabled}
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={(event): void => onCheck(event.target.checked)}
      />
      <label className={labelStyle} htmlFor={id}>{label}</label>
    </div>
  );
};

export default CheckBoxInput;
