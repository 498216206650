export enum SupportedErrorTypes {
  unexpectedError = 'unexpected-error',
  ticketNotFound = 'not-found',
  stylesheetNotFound = 'no-stylesheet',
  alreadyCompleted = 'completed',
  closed = 'closed',
  canceled = 'canceled',
  noAppointmentYet = 'no-appointment-yet',
  maintenance = 'maintenance',
  appointmentNotPossible = 'appointment-not-possible',
  noSignatureFound = 'signature-not-found'
}

const getErrorTitle = (t: (x: string) => string, errorType: string | undefined): string => {
  switch (errorType) {
    case SupportedErrorTypes.ticketNotFound:
      return t('errorMessages:caseNotFound');
    case SupportedErrorTypes.noSignatureFound:
      return t('errorMessages:signatureNotFound');
    case SupportedErrorTypes.alreadyCompleted:
      return t('errorMessages:alreadyCompleted');
    case SupportedErrorTypes.canceled:
      return t('errorMessages:canceled');
    case SupportedErrorTypes.closed:
      return t('errorMessages:closed');
    case SupportedErrorTypes.noAppointmentYet:
      return t('errorMessages:noAppointmentYet');
    case SupportedErrorTypes.stylesheetNotFound:
      return t('errorMessages:noStylesheet');
    case SupportedErrorTypes.maintenance:
      return t('errorMessages:maintenance');
    case SupportedErrorTypes.appointmentNotPossible:
      return t('errorMessages:appointmentNotPossible');
    default:
      return t('errorMessages:default');
  }
};

export { getErrorTitle };
