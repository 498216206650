import { FC } from 'react';
import { Widget } from '@typeform/embed-react';

interface FeedbackWidgetProps {
  feedbackQuestionsId?: string;
  serviceAppointmentId?: string;
}

const FeedbackWidget: FC<FeedbackWidgetProps> = ({ feedbackQuestionsId, serviceAppointmentId }) => {
  if (!feedbackQuestionsId || !serviceAppointmentId) {
    return null;
  }

  return (
    <Widget
      id={feedbackQuestionsId}
      style={{ height: '560px', marginTop: '24px' }}
      typeform-welcome={0}
      inlineOnMobile
      hidden={{
        service_appointment_id: serviceAppointmentId
      }}
    />
  );
};

export default FeedbackWidget;
