import classnames from 'classnames';
import React, { FC } from 'react';
import { Settings } from '../../interfaces/Stylesheet';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import style from './PreHeader.module.scss';

interface PreHeaderProps {
  settings: Settings;
}

const PreHeaderComponent: FC<PreHeaderProps> = ({ settings }: PreHeaderProps) => {
  const containerClasses = classnames('container', style.preheader);

  return (
    <div className={style.ticketHeader}>
      <div className={containerClasses}>
        {!!settings.logoUrl && (
          <img
            src={settings.logoUrl}
            className={style.ticketHeaderItem}
            alt={settings.partnerName + ' logo'}
          />
        )}
        <div className={style.ticketHeaderRightCol}>
          {!!settings.showGuidionLogo && (
            <img
              src="https://cdn.guidion.com/guidion-branding/logo-medium.png"
              alt="Guidion logo"
              className={style.ticketHeaderItem}
            />
          )}
          <LanguageSelector
            className={style.ticketHeaderItem}
            placement="header-desktop"
          />
        </div>
      </div>
    </div>
  );
};

export const PreHeader = React.memo(PreHeaderComponent);
