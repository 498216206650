import { FC, useContext } from 'react';

import style from './Footer.module.scss';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { TermsAndConditions } from '../TermsAndConditions/TermsAndConditions';
import { PreFooter } from '../PreFooter/PreFooter';

const Footer: FC = (): JSX.Element | null => {
  const { stylesheet } = useContext(StylesheetContext);

  if (!stylesheet) {
    return null;
  }

  const { settings } = stylesheet;

  return (
    <footer>
      <div className={style.wrapper}>
        <div className="container">
          <PreFooter stylesheet={stylesheet} />
          <span className={style.separator} />
          <TermsAndConditions partner={settings.partnerName} link={settings.termsUrl} />
          <LanguageSelector placement="footer-mobile" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
