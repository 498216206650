export default {
  nl: {
    errorMessages: {
      canceled: 'Afspraak helaas niet gevonden.',
      caseNotFound: 'Afspraak helaas niet gevonden.',
      signatureNotFound: 'Handtekening is niet gevonden of verlopen',
      closed: 'Afspraak helaas niet gevonden.',
      appointmentNotPossible: 'Het maken van een afspraak is helaas nog niet mogelijk.',
      noAppointmentYet: 'Het maken van een afspraak is helaas nog niet mogelijk.',
      alreadyCompleted: 'De afspraak heeft al plaatsgevonden.',
      noStylesheet: 'Geen layout gevonden.',
      maintenance: 'Under construction',
      default: 'Er is iets fout gegaan.'
    },
  },
  en: {
    errorMessages: {
      canceled: 'Your appointment has not been found.',
      caseNotFound: 'No ticket found.',
      signatureNotFound: 'Signature was not found or expired',
      closed: 'Your appointment has not been found.',
      appointmentNotPossible: 'Unfortunately you can\'t make an appointment yet.',
      noAppointmentYet: 'You can\'t make an appointment yet',
      alreadyCompleted: 'You already have had an appointment',
      noStylesheet: 'No layout found.',
      maintenance: 'Under construction',
      default: 'Something went wrong.'
    },
  }
};
