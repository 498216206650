import { FC, RefObject, useCallback, useMemo } from 'react';
import { handleKeyboardDown, KeyDownHandler } from '../../helpers/Accessibility';

import style from './LanguageSelector.module.scss';

interface LanguageSelectorOptionProps {
  icon: string;
  label: string;
  onClick: (keyboardSelect: boolean) => void;
  alt: string;
  index: number;
  optionRefs: RefObject<HTMLLIElement>[];
}

export const LanguageSelectorOption: FC<LanguageSelectorOptionProps> = ({
  icon, label, onClick, alt, optionRefs, index
}) => {
  const tabHandler = useCallback(() => {
    const nextIndex = index + 1 === optionRefs.length ? 0 : index + 1;
    optionRefs[nextIndex].current?.focus();
  }, [index, optionRefs]);

  const onKeyboardSelect = useCallback(() => {
    onClick(true);
  }, [onClick]);

  const onClickSelect = useCallback(() => {
    onClick(false);
  }, [onClick]);

  const handlers: KeyDownHandler[] = useMemo(() => (
    [
      { callback: onKeyboardSelect, type: 'SELECT' },
      { callback: tabHandler, type: 'TAB' },
    ]
  ), [onKeyboardSelect, tabHandler]);

  const keyDownHandler = useMemo(() => handleKeyboardDown(handlers), [handlers]);

  return (
    <li
      className={style.selectorOption}
      onClick={onClickSelect}
      tabIndex={0}
      onKeyDown={keyDownHandler}
      ref={optionRefs[index]}
      role="menuitem"
      aria-label={label}
    >
      <img src={icon} alt={alt} />
      <span>{label}</span>
    </li>
  );
};

export default LanguageSelectorOption;
