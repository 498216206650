import './themes/main.scss';

import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Main from './Main';
import { TicketProvider } from './contexts/TicketContext';
import { StylesheetProvider } from './contexts/StylesheetContext';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { StepsProvider } from './components/Steps/StepsContext';
import GAListener from './components/Analytics/Analytics';

import { LoadingProvider } from './contexts/LoaderContext';
import { SignatureProvider } from './contexts/SignatureContext';
import { CustomerFormProvider } from './contexts/CustomerFormContext';
import { BusinessReviewsProvider } from './contexts/BusinessReviewsContext';

export const App: FC = () => {
  return (
    <GAListener>
      <BusinessReviewsProvider>
        <TicketProvider>
          <CustomerFormProvider>
            <SignatureProvider>
              <StylesheetProvider>
                <StepsProvider>
                  <LoadingProvider>
                    <Header />
                    <Main />
                    <Footer />
                  </LoadingProvider>
                </StepsProvider>
              </StylesheetProvider>
            </SignatureProvider>
          </CustomerFormProvider>
        </TicketProvider>
      </BusinessReviewsProvider>
    </GAListener>
  );
};

const router = createBrowserRouter([
  { path: '*', Component: App },
]);

export const AppRoot: FC = () => {
  return <RouterProvider router={router} />;
};
