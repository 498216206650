import React from 'react';
import { FCWithChildren } from '../interfaces/Shared';

export interface LoadingProviderStore {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const LoadingContext = React.createContext({} as LoadingProviderStore);

const LoadingProvider: FCWithChildren = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const store: LoadingProviderStore = {
    isLoading,
    setIsLoading
  };

  return (
    <LoadingContext.Provider value={store}>
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingProvider };

export const LoadingConsumer = LoadingContext.Consumer;
