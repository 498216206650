import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DateTime from '../../helpers/DateTime';
import { sendDatebarLoadMore, sendDatebarScroll } from '../../services/Analytics';
import Button from '../Button';
import { DateButton } from './DateButton';
import { LoadMoreSlots } from './LoadMoreSlots';
import style from './DateBar.module.scss';
import { SlotsDictionary } from '../../interfaces/TopSlot';

interface DateBarProps {
  slots: SlotsDictionary;
  startDate: Date;
  endDate: Date;
  selectedDate: string;
  setSelectedDate: (slot: string) => void;
  onLoadMore: () => void;
  areLoadingSlots: boolean;
  canRetrieveMore: boolean;
}

const BUTTON_WIDTH_MOBILE = 106;
const BUTTON_WIDTH_DESKTOP = 116;
const THREE_BTNS = BUTTON_WIDTH_DESKTOP * 3;

const DateBar: FC<DateBarProps> = ({
  slots, startDate, endDate, selectedDate, setSelectedDate, onLoadMore, areLoadingSlots, canRetrieveMore
}) => {
  const { t } = useTranslation();
  const [range, setRange] = useState(DateTime.rangeDays(startDate, endDate));
  const datebarRef = useRef<HTMLDivElement>(null);

  const [canScrollRight, setCanScrollRight] = React.useState(true);
  const [canScrollLeft, setCanScrollLeft] = useState(false);

  let lastPosition = 0;

  const move = (): void => {
    const currentPosition = datebarRef.current?.scrollLeft || 0;

    // not send on small scroll
    if (Math.abs(currentPosition - lastPosition) < BUTTON_WIDTH_MOBILE) {
      lastPosition = currentPosition;
      return;
    }

    const direction = lastPosition <= currentPosition ? 'right' : 'left';
    sendDatebarScroll(direction);
    lastPosition = currentPosition;
  };

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    const currentDatebarRefValue = datebarRef.current;

    if (isMobile) {
      currentDatebarRefValue?.addEventListener('touchend', move);
    }

    return (): void => {
      currentDatebarRefValue?.removeEventListener('touchend', move);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRange(DateTime.rangeDays(startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    if (!areLoadingSlots && datebarRef.current) {
      // when range was changed we should make an assumption if there is
      // some space to scroll right
      const element = datebarRef.current;
      const scrollWidth = range.length * BUTTON_WIDTH_DESKTOP - element.clientWidth;
      setCanScrollRight(element.scrollLeft < scrollWidth);
    }
  }, [areLoadingSlots, range]);

  useEffect(() => {
    const dateIndex = range.indexOf(selectedDate);
    const isMobile = window.innerWidth < 768;
    const halfOfVisibleDateBarWidth = datebarRef.current ? datebarRef.current.clientWidth / 2 : 0;
    const dateBarButtonWidth = isMobile ? BUTTON_WIDTH_MOBILE : BUTTON_WIDTH_DESKTOP;

    const toPosition = (dateIndex + 0.5) * dateBarButtonWidth - halfOfVisibleDateBarWidth;
    datebarRef.current?.scroll({ left: toPosition });
    setCanScrollLeft(toPosition > 0);

  }, [selectedDate, datebarRef, range]);

  const onScroll = (direction: 'left' | 'right'): void => {
    const element = datebarRef.current;

    if (element) {
      sendDatebarScroll(direction);
      const isToLeft = direction === 'left' ? -1 : 1;
      const toPosition = element.scrollLeft + (isToLeft * THREE_BTNS);
      const scrollWidth = element.scrollWidth - element.clientWidth;
      setCanScrollLeft(toPosition > 0);
      setCanScrollRight(toPosition < scrollWidth);
      element.scroll({ left: toPosition, behavior: 'smooth' });
    }
  };

  const onScrollLeft = (): void => {
    onScroll('left');
  };

  const onScrollRight = (): void => {
    onScroll('right');
  };

  const onSelect = (slot: string): void => {
    setSelectedDate(slot);
  };

  const handleLoadMore = React.useCallback(() => {
    sendDatebarLoadMore();
    onLoadMore();
  }, [onLoadMore]);


  return (
    <div className={style.datebarCarousel}>
      <Button
        className={style.datebarControlLeft}
        icon="arrow_back_ios"
        onClick={onScrollLeft}
        disabled={!canScrollLeft}
        ariaLabel={t('slots:showPreviousSlots')}/>
      <div className={style.datebar} ref={datebarRef}>
        {range.map((day: string) => {
          const isDisabled = !slots[day];

          return (
            <DateButton
              key={day}
              slot={day}
              selected={selectedDate === day}
              disabled={isDisabled}
              onSelect={onSelect}
            />
          );
        })}
        {!!canRetrieveMore && <LoadMoreSlots mobile loadMoreSlots={handleLoadMore} loading={areLoadingSlots} />}
        <div className={style.extraSpace} />
      </div>
      {!!canScrollRight && !areLoadingSlots && (
        <Button
          className={style.datebarControlRight}
          icon="arrow_forward_ios"
          onClick={onScrollRight}
          ariaLabel={t('slots:showNextSlots')}
        />
      )}
      {!canScrollRight && !areLoadingSlots && (
        <Button
          ariaLabel={t('slots:loadMoreSlots')}
          className={style.loadMoreDesktop}
          icon="add"
          onClick={handleLoadMore}
          disabled={!canRetrieveMore}
        />
      )}
      {!!areLoadingSlots && <LoadMoreSlots loading />}
    </div>
  );
};

export default DateBar;
