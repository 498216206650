import React from 'react';
import { useTranslation } from 'react-i18next';

import ContainerHeader from '../../components/ContainerHeader/ContainerHeader';
import Navigation from '../../components/Navigation/Navigation';
import NavigationButton from '../../components/Navigation/NavigationButton';
import { StepsUpdateContext } from '../../components/Steps/StepsContext';

import styles from './Faq.module.scss';
import { FCWithChild } from '../../interfaces/Shared';

const FaqHeader: FCWithChild = ({ children }) => {
  const { navigateToActiveStep } = React.useContext(StepsUpdateContext);
  const { t } = useTranslation();

  return (
    <ContainerHeader title={t('faq:title')} text={t('faq:text')} containerHeaderClassName={styles.faqHeader}>
      <Navigation className={styles.navigationContainer}>
        <NavigationButton
          text={t('button:back')}
          nextDisabled={false}
          buttonAction="prev"
          onClickOverride={navigateToActiveStep}
        />
      </Navigation>
      {children}
    </ContainerHeader>
  );
};

export default FaqHeader;
