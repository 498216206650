import classNames from 'classnames';
import style from './FadeInOutWrapper.module.scss';
import { FCWithChildren } from '../../interfaces/Shared';

interface FadeInOutWrapperProps {
  isEnabled: boolean;
}

const FadeInOutWrapper: FCWithChildren<FadeInOutWrapperProps> = ({
  children,
  isEnabled
}) => {

  const fadeClasses = classNames(style.fade, {
    [style.fadeIn]: isEnabled
  });

  return (
    <>
      <div className={fadeClasses}>{children}</div>
    </>
  );
};

export default FadeInOutWrapper;
