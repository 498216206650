import { createContext, useState } from 'react';

import { Signature } from '../interfaces/Signature';
import { FCWithChild } from '../interfaces/Shared';

export interface SignatureProviderStore {
  signature: Signature | undefined;
  setSignature: (signature: Signature | undefined) => void;
}

export const SignatureContext = createContext({} as SignatureProviderStore);

const SignatureProvider: FCWithChild = ({ children }) => {
  const [signature, setSignature] = useState<Signature | undefined>(undefined);

  return (
    <SignatureContext.Provider value={{ signature, setSignature }}>
      {children}
    </SignatureContext.Provider>
  );
};

export { SignatureProvider };

export const SignatureConsumer = SignatureContext.Consumer;
