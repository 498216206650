import { FC } from 'react';
import classNames from 'classnames';

import styles from './IconButton.module.scss';
import { IconNames } from '../MaterialIcon/MaterialIcon';

interface IconButtonProps {
  icon: IconNames;
  onClick?: () => void;
  className?: string;
}

const IconButton: FC<IconButtonProps> = ({ icon, className, onClick }) => {
  return (
    <button className={classNames(styles.button, className)} onClick={onClick}>
      <i aria-hidden="true" className={classNames('material-icons')}>{icon}</i>
    </button>
  );
};

export default IconButton;
