import { FunctionComponent } from 'react';
import { t } from 'i18next';

import StarRating from './StarRating';
import styles from './ExpertInfo.module.scss';

interface ExpertInfoProps {
  appointments?: string;
  yearsAtGuidion?: string;
  rating?: string;
}

const ExpertInfo: FunctionComponent<ExpertInfoProps> = ({ appointments, yearsAtGuidion, rating }) => {
  return (
    <div className={styles.expertRating}>
      {!!appointments && (
        <div className={styles.expertRatingItem}>
          <span>{appointments}</span>
          <span className={styles.expertRatingItemDescription}>{t('visitcard:serviceVisits')}</span>
        </div>
      )}
      {!!yearsAtGuidion && (
        <div className={styles.expertRatingItem}>
          <span>{yearsAtGuidion}</span>
          <span className={styles.expertRatingItemDescription}>{t('visitcard:yearsAtGuidion')}</span>
        </div>
      )}
      {!!rating && (
        <div className={styles.expertRatingItem}>
          <StarRating value={Number(rating)} />
          <span className={styles.expertRatingItemDescription}>{t('visitcard:rating')}</span>
        </div>
      )}
    </div>
  );
};

export default ExpertInfo;
