import { FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitStatus } from './interfaces/Contact';
import { Ticket } from '../../interfaces/Ticket';
import { StepsUpdateContext } from '../../components/Steps/StepsContext';
import formStyles from '../../components/Form/Form.module.scss';
import Alert from '../../components/Alert/Alert';
import NavigationButton from '../../components/Navigation/NavigationButton';
import Navigation from '../../components/Navigation/Navigation';
import FormElement, { FormChangeEvent } from '../../components/Form/FormElement';
import Button from '../../components/Button';

import TicketService from '../../services/TicketService';
import ValidationService from '../../services/ValidationService';

interface ContactFormProps {
  ticket: Ticket
}

type Errors = {
  mobileNumber?: string;
  question?: string;
};

const ContactForm: FC<ContactFormProps> = ({ ticket }) => {
  const { navigateToActiveStep } = useContext(StepsUpdateContext);
  const [isLoading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>({ success: false, error: false });
  const [formValues, setFormValues] = useState({
    mobileNumber: ticket.customer?.mobileNumber || '',
    question: ''
  });

  const [errors, setErrors] = useState<Errors>({});
  const { t } = useTranslation();

  const validateForm = useCallback((): boolean => {
    const { mobileNumber, question } = formValues;

    const validMobileNumber = ValidationService.isPhoneValid(mobileNumber);
    const validQuestion = question.length > 0;

    if (!validMobileNumber || !validQuestion ) {
      setErrors({
        mobileNumber: !validMobileNumber ? t('form:requiredField'): '',
        question: !validQuestion ? t('form:requiredField'): ''
      });
      return false;
    }

    return true;
  }, [t, formValues]);

  const onChange = useCallback((event: FormChangeEvent): void => {
    if (Object.keys(errors).length) {
      setErrors({ ...errors, [event.target.name]: '' });
    }

    if (Object.values(submitStatus).some(value => value)) {
      setSubmitStatus({ success: false, error: false })
    }

    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }, [errors, formValues, submitStatus]);

  const onSubmit = useCallback((): void => {
    if (!validateForm()){
      return
    }

    setLoading(true);
    const { mobileNumber, question } = formValues;

    new TicketService().sendContactQuestion(ticket.hashId, {
      mobilePhone: mobileNumber, question: question, ticketId: ticket.id
    }).then(() => {
      setSubmitStatus({ ...submitStatus, success: true });
    }).catch(() => {
      setSubmitStatus({ ...submitStatus, error: true });
    }).finally(() => {
      setLoading(false);
    });
  }, [submitStatus, validateForm, formValues, ticket]);

  const areValuesEmpty = Object.values(formValues).some(value => value.length === 0);
  const areErrors = Object.values(errors).some(value => value && value.length > 0);
  const isDisabled = areValuesEmpty || areErrors;

  return (
    <>
      <form>
        <FormElement
          type="text"
          name="email"
          value={ticket?.customer?.email || ''}
          label={t('form:email')}
          disabled
        />
        <FormElement
          type="text"
          name="mobileNumber"
          value={formValues['mobileNumber']}
          onChange={!isLoading ? onChange : undefined}
          label={t('form:phoneNumber')}
          error={errors.mobileNumber}
        />
        <FormElement
          type="textarea"
          name="question"
          value={formValues['question']}
          onChange={!isLoading ? onChange : undefined}
          label={t('form:question')}
          error={errors.question}
        />
        <div className={formStyles.formButtons}>
          <Navigation>
            <NavigationButton
              text={t('button:back')}
              nextDisabled={false}
              buttonAction="prev"
              onClickOverride={navigateToActiveStep}
            />
          </Navigation>
          <Button
            disabled={isLoading || submitStatus.success || isDisabled}
            text={t('button:send')}
            onClick={onSubmit}
          />
        </div>
      </form>

      {(isLoading || submitStatus.success || submitStatus.error) && (
        <Alert
          icon={isLoading
            ? undefined
            : submitStatus.success
              ? 'check_circle'
              : submitStatus.error
                ? 'error'
                : undefined
          }
          text={isLoading
            ? t('contact:loadingMessage')
            : submitStatus.success
              ? t('contact:successMessage')
              : submitStatus.error
                ? t('contact:errorMessage')
                : ''
          }
        />
      )}
    </>
  );
};

export default ContactForm;
