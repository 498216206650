import { FC } from 'react';
import classNames from 'classnames';

export type IconNames = 'expand_more' | 'room' | 'undo' | 'error' | 'access_time' | 'info' | 'calendar_today' | 'calendar_month'
| 'eco' | 'push_pin' | 'error_outline' | 'check_circle' | 'paper' | 'star' | 'phone' | 'wysiwyg' | 'search' | 'add'
| 'arrow_forward_ios' | 'arrow_back_ios';

interface IconProps {
  name: IconNames;
  color?: string;
  isPartnerFontColor?: boolean;
  ariaHidden?: boolean;
  className?: string;
  size?: number
}

const MaterialIcon: FC<IconProps> = ({ name, ariaHidden, className, isPartnerFontColor, size, color }) => {
  const iconStyles = classNames('material-icons', className, {
    'partner-font-color': !!isPartnerFontColor && !color,
  });

  const dynamicStyles = {
    ...(size ? { fontSize: size } : undefined),
    ...(color ? { color: color } : undefined),
  };

  return (
    <i aria-hidden={ariaHidden || true} style={dynamicStyles} className={iconStyles}>{name}</i>
  );
};

export default MaterialIcon;
