import { Technician } from '../interfaces/Technician';
import BackendRepository from '../api/interfaces/BackendRepository';
import BackendRepositoryRemote from '../api/BackendRepositoryRemote';

export default class TechnicianService {
  private repository: BackendRepository;

  constructor() {
    this.repository = new BackendRepositoryRemote();
  }

  public async getTechnician(ticketHashId: string): Promise<Technician> {
    const technician = await this.repository.getTechnician(ticketHashId);

    return technician;
  }
}
