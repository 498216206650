import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import NavigationButton from '../../../components/Navigation/NavigationButton';
import Navigation from '../../../components/Navigation/Navigation';
import style from './AcceptData.module.scss';

interface AcceptDataProps {
  onSave: () => Promise<boolean>;
  isLoading: boolean;
}

const AcceptData: FC<AcceptDataProps> = ({ isLoading, onSave }) => {
  const { t } = useTranslation();

  return (
    <div className={style.acceptData}>
      <div className={style.buttonsContainer}>
        <Navigation>
          <NavigationButton
            buttonAction="next"
            text={t('button:nextStep')}
            nextDisabled={isLoading}
            preNavigateAction={onSave}
          />
        </Navigation>
      </div>
    </div>
  );
};

export default AcceptData;
