import classnames from 'classnames';

import style from './BlockBG.module.scss';
import { FCWithChildren } from '../../interfaces/Shared';

interface BlockBG {
  classStyle?: string;
}

const BlockBG: FCWithChildren<BlockBG> = ({ classStyle, children }) => {
  const classes = classnames(style.bg, classStyle);

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default BlockBG;
