import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import style from './PreFooter.module.scss';

interface FooterSectionColumnProps {
    title: string;
    link?: string;
}


export const FooterSectionColumn: FC<PropsWithChildren<FooterSectionColumnProps>> = ({ children, title, link }) => {
  return (
    <div className="col-sm-12 col-md-4">
      {link ? (
        <Link className={style.footerItemHeader} to={link}>
          <h2>{title}</h2>
        </Link>
      ) : (
        <h2>{title}</h2>
      )}
      {children}
    </div>
  );
};
