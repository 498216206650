import { Calendar, Urls } from '../interfaces/Calendar';
import UrlHelper from '../helpers/url';

const calcDuration = (finish: string, start: string): string => {
  const minutes = Math.floor((+new Date(finish) - +new Date(start)) / 60 / 1000);

  return `${Math.floor(minutes / 60)}:${`0${minutes % 60}`.slice(-2)}`;
};

const formatTime = (time: string): string => {
  return new Date(time).toISOString().replace(/[-:]|\.\d{3}/g, '');
};

const makeGoogleCalendarUrl = (calendar: Calendar): string => {
  const { address, description, finish, start, title } = calendar;

  return UrlHelper.makeUrl('https://www.google.com/calendar/render', {
    action: 'TEMPLATE',
    dates: `${formatTime(start)}/${formatTime(finish)}`,
    location: address,
    text: title,
    details: description
  });
};

const makeYahooCalendarUrl = (calendar: Calendar): string => {
  const { address, description, finish, start, title } = calendar;

  return UrlHelper.makeUrl('http://calendar.yahoo.com', {
    v: 60,
    view: 'd',
    type: 20,
    title: title,
    st: formatTime(start),
    dur: calcDuration(finish, start),
    desc: description,
    in_loc: address
  });
};

const makeICSCalendarUrl = (calendar: Calendar): string => {
  const { address, description, finish, start, title } = calendar;
  const startTime = formatTime(start);
  const endTime = formatTime(finish);
  const components = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${document.URL}`,
    `DTSTART:${startTime}`,
    `DTEND:${endTime}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${description}`,
    `LOCATION:${address}`,
    'END:VEVENT',
    'END:VCALENDAR'
  ];

  return encodeURI(`data:text/calendar;charset=utf8,${components.join('\n')}`);
};

const makeOutlookCalendarUrl = (calendar: Calendar): string => {
  const { address, description, finish, start, title } = calendar;

  return UrlHelper.makeUrl('https://outlook.live.com/owa', {
    rru: 'addevent',
    startdt: formatTime(start),
    enddt: formatTime(finish),
    subject: title,
    location: address,
    body: description,
    allday: false,
    path: '/calendar/view/Month'
  });
};

const getUrls = (calendar: Calendar): Urls => ({
  google: makeGoogleCalendarUrl(calendar),
  yahoo: makeYahooCalendarUrl(calendar),
  ics: makeICSCalendarUrl(calendar),
  outlook: makeOutlookCalendarUrl(calendar),
});

export default getUrls;
