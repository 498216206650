import { useFormatting } from '../../services/hooks/useFormatting';
import { FCWithChildren } from '../../interfaces/Shared';

interface ContainerHeaderProps {
  title: string;
  text: string;
  containerHeaderClassName?: string;
}

const ContainerHeader: FCWithChildren<ContainerHeaderProps> = ({ title, text, containerHeaderClassName, children }) => {
  const { formattedText: containerTitle } = useFormatting(title);
  const { formattedText: containerText } = useFormatting(text);

  return (
    <div className={containerHeaderClassName}>
      <h1 dangerouslySetInnerHTML={{ __html: containerTitle }} />
      <p dangerouslySetInnerHTML={{ __html: containerText }} />
      {children}
    </div>
  );
};

export default ContainerHeader;
