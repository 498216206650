import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useTechnician } from './useTechnician';
import ExpertInfo from './ExpertInfo';
import Card from '../../../components/Card/Card';

import styles from './ExpertCard.module.scss';

export interface ExpertCardProps {
  ticketId: string;
}

const ExpertCard: FunctionComponent<ExpertCardProps> = ({ ticketId }) => {
  const { t } = useTranslation();
  const expert = useTechnician(ticketId);

  if (!expert) return null;
  const profileImage = expert.profileImage || '/assets/guido-3c674837c1b8c5f7fa8a7aa8ecf85cca.png';

  return (
    <Card classStyle={styles.cardContainer}>
      <div className={styles.expertId}>
        <div className={styles.expertImage}>
          <img src={profileImage} alt={t('expertCardAlts:expertPhotograph')} />
        </div>
        <div className={styles.expertInfo}>
          <span className={styles.expertName}>{expert.name}</span>
          {!!expert.city && (
            <div className={styles.expertLocation}>
              <i className={classNames('material-icons', 'partner-font-color', styles.locationIcon)}>{'room'}</i>
              <span className={styles.expertCity}>{expert.city}</span>
            </div>
          )}
        </div>
      </div>
      <ExpertInfo appointments={expert.appointments} rating={expert.rating} yearsAtGuidion={expert.yearsAtGuidion}/>
    </Card>
  );
};

export default ExpertCard;
