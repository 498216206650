import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './NoSlotsSummary.module.scss';
import { NoTopSlot } from '../../interfaces/NoTopSlot';
import Card from '../Card/Card';

import DateTime from '../../helpers/DateTime';

interface NoSlotButtonProps {
  selectedNoTopSlots: NoTopSlot[];
}

const NoSlotsSummary: FC<NoSlotButtonProps> = ({ selectedNoTopSlots }) => {
  const { t } = useTranslation();

  return (
    <Card classStyle={styles.warningContainer}>
      <i className={classNames('material-icons', 'partner-font-color', styles.itemIcon)}>{'calendar_today'}</i>
      <div className={styles.warningText}>
        <p className={styles.warningTitle}>{t('confirmSection:selectedNoTopSlotsTitle')}</p>
        {selectedNoTopSlots.map(({ day, periods }) => (
          <p key={day}>{t('confirmSection:selectedNoTopSlotsContent', {
            date: DateTime.formatDate('EEEE dd LLLL', new Date(day)),
            periods: periods.map(({ from, to }, index) => (
              `${DateTime.getTimeStringFromDate(DateTime.roundToNearestMinutes(from))}
                - ${DateTime.getTimeStringFromDate(DateTime.roundToNearestMinutes(to))}${index !== periods.length - 1
                ? ',' : ''}`
            )).join(' ')
          })}
          </p>
        ))}
      </div>
    </Card>
  );
};

export default NoSlotsSummary;
