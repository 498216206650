import { Stylesheet } from '../interfaces/Stylesheet';
import { Ticket } from '../interfaces/Ticket';
import Language from '../helpers/Language';
import DateTime from '../helpers/DateTime';
import { sendChatRequested } from './Analytics';

export interface ChatTexts {
  loading: string;
  employeeAvailableText: string;
  employeeNotAvailableText: string;
  firstNameFieldLabel: string;
  lastNameFieldLabel: string;
}

const initESW = (forceSalesforceBaseUrl: string | null, stylesheet: Stylesheet, ticket: Ticket, chatTexts: ChatTexts): void => {
  const embeddedSvc = window['embedded_svc'];
  embeddedSvc.settings.defaultMinimizedText = chatTexts.employeeAvailableText;
  embeddedSvc.settings.disabledMinimizedText = chatTexts.employeeNotAvailableText;
  embeddedSvc.settings.loadingText = chatTexts.loading;
  embeddedSvc.settings.displayHelpButton = true;
  embeddedSvc.settings.language = Language.getChatLanguage();
  embeddedSvc.settings.storageDomain = import.meta.env.VITE_SALESFORCE_CHAT_STORAGE_DOMAIN;
  embeddedSvc.settings.enabledFeatures = ['LiveAgent'];
  embeddedSvc.settings.entryFeature = 'LiveAgent';
  embeddedSvc.settings.avatarImgURL = 'https://cdn.guidion.com/guidion-branding/guido.png';
  embeddedSvc.settings.prepopulatedPrechatFields = {
    FirstName: ticket.customer?.firstName || '',
    LastName: ticket.customer?.lastName || '',
    Email: ticket.customer?.email || '',
  };
  embeddedSvc.settings.extraPrechatFormDetails = [{
    label: 'Case',
    name: 'RSS_Hashed_Id__c',
    value: ticket.hashId,
    displayToAgent: false
  }];

  embeddedSvc.settings.extraPrechatInfo = [{
    entityFieldMaps: [{
      doCreate: false,
      doFind: true,
      fieldName: 'LastName',
      isExactMatch: true,
      label: chatTexts.lastNameFieldLabel,
    }, {
      doCreate: false,
      doFind: true,
      fieldName: 'FirstName',
      isExactMatch: true,
      label: chatTexts.firstNameFieldLabel
    }],
    entityName: 'Contact',
  },
  {
    entityFieldMaps: [{
      doCreate: false,
      doFind: true,
      fieldName: 'RSS_Hashed_Id__c',
      isExactMatch: true,
      label: 'Case'
    }],
    entityName: 'Case',
    saveToTranscript: 'Case'
  }];

  embeddedSvc.init(
    import.meta.env.VITE_SALESFORCE_BASE_URL,
    import.meta.env.VITE_SALESFORCE_CHAT_COMMUNITY_URL,
    forceSalesforceBaseUrl,
    import.meta.env.VITE_SALESFORCE_ORG_DEPLOYMENT_ID,
    'SelfService',
    {
      baseLiveAgentContentURL: `${import.meta.env.VITE_SALESFORCE_CHAT_LIVE_AGENT_CONTENT_BASE_URL}/content`,
      deploymentId: stylesheet.salesforceChat!.deploymentId,
      buttonId: stylesheet.salesforceChat!.buttonId,
      baseLiveAgentURL: `${import.meta.env.VITE_SALESFORCE_CHAT_LIVE_AGENT_BASE_URL}/chat`,
      eswLiveAgentDevName: 'Chat_Users',
      isOfflineSupportEnabled: false
    }
  );

  embeddedSvc.addEventHandler('onHelpButtonClick', () => {
    sendChatRequested(window.location.pathname);
  });
};

const initializeChat = (stylesheet: Stylesheet, ticket: Ticket, chatTexts: ChatTexts): void => {
  if (!stylesheet.salesforceChat) {
    return;
  }

  if (stylesheet.salesforceChat.rssHashId && stylesheet.salesforceChat.rssHashId !== ticket.hashId) {
    return;
  }

  const startTime = DateTime.dateFromTime(stylesheet.salesforceChat.startTime);
  const endTime = DateTime.dateFromTime(stylesheet.salesforceChat.endTime);
  const now = Date.now();

  if (now < startTime.getTime() || now > endTime.getTime()) {
    return;
  }

  if (!window['embedded_svc']) {
    const s = document.createElement('script');
    s.setAttribute('src', `${import.meta.env.VITE_SALESFORCE_BASE_URL}/embeddedservice/5.0/esw.min.js`);
    s.id = 'salesforce-esw';
    s.onload = (): void => {
      initESW(null, stylesheet, ticket, chatTexts);
    };
    document.body.appendChild(s);
  }
};


export default { initializeChat };
