const TICKET_ID_KEY = 'ticketId';

const setTicketIdInLocalStorage = (id: string): void => {
  try {
    localStorage.setItem(TICKET_ID_KEY, id);
  } catch (error) {
    // An error can occur because someone disabled cookies/etc in their browser.
    console.error(error);
  }
};

const getTicketIdFromLocalStorage = (): string | undefined => {
  try {
    return localStorage.getItem(TICKET_ID_KEY) || undefined;
  } catch (error) {
    // An error can occur because someone disabled cookies/etc in their browser.
    console.error(error);
    return undefined;
  }
};

export { setTicketIdInLocalStorage, getTicketIdFromLocalStorage };
