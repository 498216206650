import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Contact.module.scss';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { TicketContext } from '../../contexts/TicketContext';
import ContactSidebar from './ContactSidebar';
import ContactForm from './ContactForm';

import usePageTitle from '../../services/hooks/usePageTitle';
import { useScrollToTop } from '../../helpers/hooks/useScrollToTop';

const ContactPage: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);
  const { ticket } = useContext(TicketContext);

  const { t } = useTranslation();

  usePageTitle(t('pageTitle:contact'));
  useScrollToTop();

  if (!stylesheet) {
    return null;
  }

  return (
    <div className={styles.ticketContact}>
      <h1>{t('contact:title')}</h1>
      <p className={styles.contactDescription}>{t('contact:formDescription')}</p>
      <div className="row">
        {!stylesheet.contact.disabled && ticket && (
          <div className="col col-sm-12 col-md-12 col-lg-7">
            <ContactForm ticket={ticket} />
          </div>
        )}
        <ContactSidebar stylesheet={stylesheet} />
      </div>
    </div>
  );
};

export default ContactPage;
