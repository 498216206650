import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StylesheetContext } from '../../contexts/StylesheetContext';
import { useFormatting } from '../../services/hooks/useFormatting';
import ThanksImage from './ThanksImage';

import styles from './Thanks.module.scss';
import { TicketContext } from '../../contexts/TicketContext';
import FeedbackWidget from '../Typeform/FeedbackWidget';

const Thanks: FC = () => {
  const { stylesheet } = React.useContext(StylesheetContext);
  const { ticket } = React.useContext(TicketContext);
  const { t } = useTranslation();
  const { formattedText: noSlotsThankYou } = useFormatting(
    stylesheet?.appointmentSection.noSlotsThankYouDescriptionBlock.value,
  );

  if (!stylesheet || !ticket) {
    return null;
  }

  const feedbackQuestionsId = stylesheet.settings.feedbackQuestions?.id;
  const serviceAppointmentId = ticket.workOrder.appointment?.id || '';

  return (
    <div className={styles.noSlotsThankYou}>
      <h1>{t('noslots:thanksTitle')}</h1>
      <p dangerouslySetInnerHTML={{ __html: noSlotsThankYou }} />
      <ThanksImage />
      <FeedbackWidget feedbackQuestionsId={feedbackQuestionsId} serviceAppointmentId={serviceAppointmentId} />
    </div>
  );
};

export default Thanks;
