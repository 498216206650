import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { StepsUpdateContext } from '../../components/Steps/StepsContext';
import { sendRescheduleClicked } from '../../services/Analytics';
import Button from '../../components/Button';
import Card from '../../components/Card/Card';

import styles from './ConfirmReschedule.module.scss';

interface ConfirmRescheduleProps {
  cancelingEnabled: boolean;
  reschedulingEnabled: boolean;
}


const ConfirmReschedule: FC<ConfirmRescheduleProps> = ({ cancelingEnabled, reschedulingEnabled }) => {
  const { navigateToPrevStep } = React.useContext(StepsUpdateContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onReschedule = () => {
    sendRescheduleClicked();
    navigateToPrevStep();
  };

  const iconClassNames = classNames('material-icons', 'partner-font-color', styles.itemIcon);

  return (
    <Card classStyle={styles.rescheduleContianer}>
      <div className={styles.rescheduleTitle}>
        <i aria-hidden="true" className={iconClassNames}>{'calendar_today'}</i>
        <p className={styles.changeAppointment}> {t('confirmSection:changeAppointment')}</p>
      </div>
      <div className={styles.changeAppointmentButtonsContainer}>
        {!!reschedulingEnabled && (
          <Button
            text={t('confirmSection:change')}
            ariaLabel={t('confirmSection:change')}
            onClick={onReschedule}
            className={styles.changeAppointmentButton}
          />
        )}
        {!!cancelingEnabled && (
          <Button
            variant="outline"
            text={t('button:cancelAppointment')}
            ariaLabel={t('button:cancelAppointment')}
            onClick={() => navigate('/cancel')}
            className={styles.changeAppointmentButton}
          />
        )}
      </div>
    </Card>
  );
};

export default ConfirmReschedule;
