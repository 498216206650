export default {
  nl: {
    customerSection: {
      title: 'Welkom bij {{partner}}',
      formErrors: {
        phoneNumberRequired: 'Vul tenminste één telefoonnummer in, zodat we je kunnen informeren over je afspraak',
        emailRequired: 'Een emailadres is vereist om je te informeren over je afspraak.',
        phoneNumberInvalid: 'Telefoonnummer is niet correct',
        emailInvalid: 'E-mailadres is niet correct',
      }
    },
  },
  en: {
    customerSection: {
      title: 'Welcome to {{partner}}',
      formErrors: {
        phoneNumberRequired: 'At least one phone number is required to inform you about your appointment.',
        emailRequired: 'An email address is required to inform you about your appointment.',
        phoneNumberInvalid: 'Phone number is invalid.',
        emailInvalid: 'Email address is invalid',
      }
    },
  }
};
