import { useContext, useEffect, useState } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { Sidetab } from '@typeform/embed-react';
import classNames from 'classnames';

import {
  Appointment,
  Cancel,
  Change,
  Confirm,
  Contact,
  Customer,
  Error,
  Faq,
  Initialization,
  Questions
} from './pages';
import { TicketContext } from './contexts/TicketContext';
import FadeInOutWrapper from './components/FadeInOutWrapper/FadeInOutWrapper';
import { LoadingContext } from './contexts/LoaderContext';
import { initTypes } from './services/RedirectService';
import { StylesheetContext } from './contexts/StylesheetContext';
import Signature from './pages/Signature/Signature';
import { SignatureContext } from './contexts/SignatureContext';
import { SentryRoutes } from './components/Analytics/SentryIntegration';

const Main = (): JSX.Element => {
  const [sideTabId, setSideTabId] = useState<string | undefined>(undefined);
  const { ticket } = useContext(TicketContext);
  const { isLoading } = useContext(LoadingContext);
  const { stylesheet } = useContext(StylesheetContext);
  const { signature } = useContext(SignatureContext);

  const serviceAppointmentId = ticket?.workOrder.appointment?.id || '';

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const spinners = document.getElementsByClassName('guidion-spinner');

    if (spinners.length > 0) {
      document.body.removeChild(spinners[0]);
    }
    setSideTabId(stylesheet?.settings.feedbackSidetab?.id);
  }, [isLoading, stylesheet]);

  return (
    <main className={classNames('page', 'container', isLoading && 'hidden')}>
      <FadeInOutWrapper isEnabled={!isLoading}>
        <SentryRoutes>
          {stylesheet && [
            <Route key="/faq" path="/faq" element={<Faq />}/>,
            <Route key="/contact" path="/contact" element={<Contact />}/>
          ]}
          {ticket && stylesheet ? [
            <Route key="/customer" path="/customer" element={<Customer ticket={ticket} stylesheet={stylesheet} />}/>,
            <Route key="/questions" path="/questions" element={<Questions />}/>,
            <Route key="/appointment" path="/appointment" element={<Appointment />}/>,
            <Route key="/confirm" path="/confirm" element={<Confirm />}/>,
            <Route key="/change" path="/change" element={<Change />}/>,
            <Route key="/cancel" path="/cancel" element={<Cancel />}/>,
          ] : [
            <Route key="/cancel" path="/cancel" element={<Navigate to={`/${initTypes.cancel}`} replace />} />
          ]}
          {!!ticket && !!signature && <Route key="/sign" path="/sign" element={<Signature />}/>}
          <Route path="/error/:id" element={<Error />}/>
          <Route path={`/${initTypes.ticket}/:id`} element={<Initialization initType={initTypes.ticket} />}/>
          <Route path={`/${initTypes.ticket}`} element={<Initialization initType={initTypes.ticket} />}/>
          <Route path={`/${initTypes.cancel}/:id`} element={<Initialization initType={initTypes.cancel} />}/>
          <Route path={`/${initTypes.cancel}`} element={<Initialization initType={initTypes.cancel} />}/>
          <Route path={`/${initTypes.signature}/:id`} element={<Initialization initType={initTypes.signature} />}/>
          <Route path={`/${initTypes.signature}`} element={<Initialization initType={initTypes.signature} />}/>
          <Route>
            <Route path="*" element={<Navigate to={`/${initTypes.ticket}`} replace />} />
          </Route>
        </SentryRoutes>
        {!!sideTabId && !!serviceAppointmentId && !signature && (
          <Sidetab
            id={sideTabId}
            width={320}
            autoClose
            buttonText="Feedback"
            hidden={{
              service_appointment_id: serviceAppointmentId
            }}
          />
        )}
      </FadeInOutWrapper>
    </main>
  );
};

export default Main;
