import React, { FC } from 'react';
import Step from './Step';
import { StepsContext } from './StepsContext';
import style from './Steps.module.scss';
import AnimatedImage from '../AnimatedImage/AnimatedImage';
import { getHeaderAnimationName } from '../../helpers/HeaderAnimation';

const Steps: FC = () => {
  const { steps } = React.useContext(StepsContext);
  const animationName = getHeaderAnimationName();

  return (
    <nav className={style.steps}>
      {animationName && (
        <AnimatedImage className={style.animation} name={animationName} />
      )}
      <ul className="container">
        {steps.map((step, i) => (
          <Step
            key={step.id}
            step={step}
            index={i + 1}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Steps;
