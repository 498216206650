import { FC, MouseEvent, RefObject } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';
import { IconNames } from '../MaterialIcon/MaterialIcon';

export interface ButtonProps {
  icon?: IconNames;
  text?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  variant?: 'text' | 'contained' | 'outline' | 'outlineDark';
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  ariaLabel?: string;
}

const Button: FC<ButtonProps> = ({
  buttonRef, icon, text, disabled, type, className, onClick, ariaLabel, variant = 'contained',
}) => {

  const isOutlined = variant === 'outline' || variant === 'outlineDark';

  const buttonClassNames = classNames({
    [styles.btn]: true,
    'partner-hightlight-background': !disabled && variant === 'contained',
    'partner-border-font-color': !disabled && (variant === 'outline' || variant === 'text'),
    [styles.btnText]: !disabled && variant === 'text',
    [styles.btnInversed]: !disabled && isOutlined,
    [styles.btnInversedDark]: !disabled && variant === 'outlineDark',
    [styles.btnDisabled]: disabled && !isOutlined,
    [styles.btnDisabledInversed]: disabled && isOutlined,
    [styles.disabledColor]: disabled,
  }, className);

  const iconClassNames = classNames('material-icons', {
    [styles.btnIcon]: true,
    [styles.btnIconOnly]: !text,
    [styles.disabledColor]: disabled,
    'partner-font-color': isOutlined && !disabled,
  });

  return (
    <button
      ref={buttonRef}
      className={buttonClassNames}
      onClick={onClick}
      type={type || 'button'}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {!!icon && <i className={iconClassNames}>{icon}</i>}
      {!!text && <span>{text}</span>}
    </button>
  );
};

export default Button;
