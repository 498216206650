const isEmailValid = (value: string): boolean => {
  // Source https://stackoverflow.com/a/46181
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(value);
};

const isPhoneValid = (value: string): boolean => {
  // Minimal check to make sure phone number consists of + and/or numbers only
  const phoneRegex = /^[\d\+]*$/;

  return phoneRegex.test(value);
};

export default {
  isEmailValid,
  isPhoneValid,
};
