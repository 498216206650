import BackendRepository from '../api/interfaces/BackendRepository';
import BackendRepositoryRemote from '../api/BackendRepositoryRemote';
import { GroupedSlot, TopSlot } from '../interfaces/TopSlot';
import DateTime from '../helpers/DateTime';
import { AvailableNoSlots, DayPeriods } from '../interfaces/AvailableNoSlots';

export interface GetTopSlotsResult {
  requestedDateEnd: Date;
  slots: GroupedSlot[];
  requestsWithError: number;
}

export default class TopSlotService {
  private repository: BackendRepository;

  constructor() {
    this.repository = new BackendRepositoryRemote();
  }

  public async getTopSlots(
    ticketHashId: string, serviceAppointmentId: string, earliestStartTime: Date, maxPlanDate: Date
  ): Promise<GetTopSlotsResult> {
    let retries = 0;
    let reachedTimeframeEnd = false;

    // create first week to request slots for
    const startDate: Date = earliestStartTime;
    let endDate = DateTime.cloneDate(startDate);
    this.addWeekToDate(endDate);

    // shorten the week if it exceeds the limit
    if (endDate > maxPlanDate) {
      endDate = DateTime.cloneDate(maxPlanDate);
      reachedTimeframeEnd = true;
    }

    let slots: GroupedSlot[] = [];
    let requestsWithError = 0;

    while (!this.isMaxRetriesReached(retries) && this.isDateBeforeMaxPlanDate(maxPlanDate, endDate)) {
      if (retries > 0) {
        this.addWeekToDate(startDate);
        this.addWeekToDate(endDate);
      }

      if (endDate > maxPlanDate) {
        // we can exceed the timeframe limit when making retries
        endDate = DateTime.cloneDate(maxPlanDate);
        reachedTimeframeEnd = true;
      }

      const startDateISO = startDate.toISOString();
      const endDateISO = endDate.toISOString();

      try {
        slots = await this.repository.getTopSlots(ticketHashId, serviceAppointmentId, startDateISO, endDateISO);

        if (slots.length) {
          return {
            slots,
            requestedDateEnd: endDate,
            requestsWithError,
          };
        }
      } catch (err) {
        requestsWithError++;
        console.log('getTopSlots error ', err);
      }

      if (reachedTimeframeEnd) {
        // if timeframe limit was reached, we don't need to continue retries
        break;
      }

      retries++;
    }

    return {
      slots,
      requestedDateEnd: endDate,
      requestsWithError,
    };
  }

  public async bookSlot(ticketHashId: string, serviceAppointmentId: string, { start, end }: TopSlot): Promise<void> {
    const startISO = new Date(start).toISOString();
    const endISO = new Date(end).toISOString();

    return this.repository.bookSlot(ticketHashId, serviceAppointmentId, startISO, endISO);
  }

  private isMaxRetriesReached(retries: number): boolean {
    const maxRetries = 3;

    return retries >= (maxRetries + 1);
  }

  private isDateBeforeMaxPlanDate(maxPlanDate: Date, date?: Date): boolean {
    return (!date || date <= maxPlanDate);
  }

  addWeekToDate(date: Date): void {
    const numberOfWeeks = 1;
    date.setDate(date.getDate() + (numberOfWeeks * 7));
  }
}

export const getNumberOfSelectedDays = (slots: AvailableNoSlots): number => {
  return Object.values(slots).reduce((total, current: DayPeriods) => {
    return total + (current.afternoon ? 1 : 0) + (current.morning ? 1 : 0);
  }, 0);
};
