import classNames from 'classnames';

import style from './Card.module.scss';
import { IconNames } from '../MaterialIcon/MaterialIcon';
import { FCWithChild } from '../../interfaces/Shared';

interface CardItemProp {
  classStyle?: string;
  icon?: IconNames;
}

const CardItem: FCWithChild<CardItemProp> = ({ classStyle, icon, children }) => {
  const classes = classNames(style.cardItem, classStyle);
  const iconClassNames = classNames('material-icons', icon !== 'error' && 'partner-font-color', style.itemIcon);

  return (
    <div className={classes}>
      {!!icon && <i aria-hidden="true" className={iconClassNames}>{icon}</i>}
      {children}
    </div>
  );
};

export default CardItem;
