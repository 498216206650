type Query = { [key: string]: null | boolean | number | string };

const makeUrl = (base: string, query: Query): string => Object.keys(query).reduce(
  (accum, key, index) => {
    const value = query[key];

    if (value !== null) {
      return `${accum}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(value)}`;
    }

    return accum;
  },
  base
);

export default {
  makeUrl
};
