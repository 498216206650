import { FC, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { StylesheetContext } from '../../contexts/StylesheetContext';
import { PreHeader } from '../PreHeader/PreHeader';
import { StickyWrapper } from './StickyWrapper';
import Steps from '../Steps/Steps';

const Header: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);
  const { pathname } = useLocation();
  const [isSticky, setIsSticky] = useState(false);

  if (!stylesheet) {
    return null;
  }

  const stepsHeight = document.getElementById('sticky')?.offsetHeight;

  return (
    <header style={{ marginBottom: isSticky ? stepsHeight : undefined }}>
      <PreHeader settings={stylesheet.settings} />
      <StickyWrapper setIsSticky={setIsSticky} isSticky={isSticky}>
        {pathname !== '/sign' && <Steps />}
      </StickyWrapper>
    </header>
  );
};

export default Header;
