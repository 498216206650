const runXTimesAndReturnOutputs = <T>(amount: number, runThis: (index: number) => T): T[] => {
  const output: T[] = [];

  for (let i = 0; i < amount; i++) {
    output.push(runThis(i));
  }

  return output;
};

export { runXTimesAndReturnOutputs };
