import { KeyboardEventHandler, useCallback, useEffect } from 'react';

type HandlerType = 'SELECT' | 'TAB';

export type KeyDownHandler = {
  callback: () => void;
  type: HandlerType;
};

const keysHash: Record<HandlerType, string[]> = {
  SELECT: ['Enter', ' '],
  TAB: ['Tab'],
};

export const handleKeyboardSelect = (callback: () => void): KeyboardEventHandler => (event) => {
  handleKeyboardDown([{ type: 'SELECT', callback }])(event);
};

export const handleKeyboardDown = (handlers: KeyDownHandler[]): KeyboardEventHandler => (event) => {
  const { key } = event;

  handlers.forEach(handler => {
    const keys = keysHash[handler.type];

    if (keys.includes(key)) {
      event.preventDefault();
      handler.callback();
    }
  });
};

export interface DocumentKeyDownOptions {
  key: string;
  handler: () => unknown;
  enabled?: boolean;
}

export const useDocumentKeyDown = (options: DocumentKeyDownOptions): void => {
  const { key, handler, enabled } = options;

  const keyDownHandler = useCallback((e: { key: string }) => {
    const { key: pressedKey } = e;

    if (pressedKey == key) {
      handler();
    }
  }, [handler, key]);

  useEffect(() => {
    if (enabled === false) {
      return;
    }

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler, enabled]);
};
