import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Cancel.module.scss';
import Button from '../../components/Button';
import Alert from '../../components/Alert/Alert';
import { StepsUpdateContext } from '../../components/Steps/StepsContext';
import Modal from '../../components/Modal/Modal';

import { TicketContext } from '../../contexts/TicketContext';
import { StylesheetContext } from '../../contexts/StylesheetContext';

import { useFormatting } from '../../services/hooks/useFormatting';
import { sendCancelAppointmentClicked } from '../../services/Analytics';
import { usePost } from '../../helpers/hooks/usePost';
import StepsService from '../../services/StepsService';
import usePageTitle from '../../services/hooks/usePageTitle';
import TicketService from '../../services/TicketService';


const Cancel: FC = () => {
  const { ticket } = useContext(TicketContext);
  const { stylesheet } = useContext(StylesheetContext);
  const { disableAllSteps, setBasicSteps } = useContext(StepsUpdateContext);
  const [ isDisabled, setIsDisabled ] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const { formattedText: cancelSectionText } = useFormatting(stylesheet?.cancelSection.textBlock.value);
  const { response: { pending, complete, error }, doRequest } = usePost();
  const success = complete && !error;
  const { t } = useTranslation();

  usePageTitle(t('pageTitle:cancel'));

  useEffect(() => {
    if (!isDisabled){
      disableAllSteps();
      setIsDisabled(true);
    }

    return (): void => {
      if (isDisabled){
        setBasicSteps(StepsService.getDefaultSteps(ticket));
      }
    };
  }, [disableAllSteps, setBasicSteps, ticket, isDisabled]);

  if (!stylesheet || !ticket) {
    throw new Error('No stylesheet or ticket loaded');
  }

  const doCancelAppointment = useCallback(() => {
    setConfirmationVisible(false);
    sendCancelAppointmentClicked();
    doRequest(new TicketService().cancelTicket(ticket.workOrder.appointment.id));
  }, [doRequest, ticket.workOrder.appointment.id]);

  const showConfirmationPopup = () => setConfirmationVisible(true);
  const hideConfirmationPopup = () => setConfirmationVisible(false);

  return (
    <div className={styles.cancelRoot}>
      <h1>{t('cancel:title')}</h1>
      <p>
        {cancelSectionText}
      </p>

      <Alert
        icon="calendar_today"
        text={t('cancel:makeAppointment')}
      >
        <a href={`/t/${ticket.hashId}`}>{t('cancel:makeAppointmentLink')}</a>
      </Alert>

      {!!success && (
        <Alert
          icon="check_circle"
          text={t('cancel:successMessage')}
        />
      )}

      {!!error && (
        <Alert
          level="error"
          text={t('cancel:errorMessage')}
        />
      )}

      {!success && (
        <div>
          <Button
            disabled={pending}
            onClick={showConfirmationPopup}
            text={t('button:cancelAppointment')}
          />
        </div>
      )}
      {!!confirmationVisible && (
        <Modal
          small
          title={t('cancel:popupTitle')}
          description={t('cancel:popupDescription', { partnerName: stylesheet.settings.partnerName })}
          onClose={hideConfirmationPopup}
          primaryLabel={t('cancel:confirmCancelButton')}
          primaryHandler={doCancelAppointment}
          secondaryLabel={t('cancel:rejectCancelButton')}
          secondaryHandler={hideConfirmationPopup}
        />
      )}
    </div>
  );
};

export default Cancel;
