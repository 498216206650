import { createRef, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Appointment.module.scss';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { Ticket } from '../../interfaces/Ticket';
import { SlotsDictionary, TopSlot } from '../../interfaces/TopSlot';

import NavigationButton from '../../components/Navigation/NavigationButton';
import Navigation from '../../components/Navigation/Navigation';
import Alert from '../../components/Alert/Alert';
import DateBar from '../../components/DateBar';
import AppointmentConfirmationText from './AppointmentConfirmationText';
import { StepsUpdateContext } from '../../components/Steps/StepsContext';
import { TicketContext } from '../../contexts/TicketContext';
import { TopSlotInfo } from '../../components/TopSlotInfo/TopSlotInfo';
import { LoadingImages } from '../../components/LoadingImages/LoadingImages';

import { usePost } from '../../helpers/hooks/usePost';
import { scrollToElement } from './AppointmentController';
import { selectedTopSlotIndexGA, sendAnalyticsDateSelected, successfullyBookedAppointmentGA } from '../../services/Analytics';
import TopSlotService from '../../services/TopSlotService';
import { useScrollToTop } from '../../helpers/hooks/useScrollToTop';


interface AppointmentWithTopSlotsProps {
  ticket: Ticket;
  stylesheet: Stylesheet;
  topSlots: SlotsDictionary;
  minDate: Date;
  maxDate: Date;
  highestSlot: TopSlot;
  onLoadMore: () => void;
  onSlotBooking: (pending: boolean) => void;
  areLoadingSlots: boolean;
  canRetrieveMore: boolean;
}

const AppointmentWithTopSlots: FC<AppointmentWithTopSlotsProps> = (props) => {
  const {
    ticket, stylesheet, topSlots, minDate, maxDate, highestSlot, onLoadMore, onSlotBooking, areLoadingSlots, canRetrieveMore
  } = props;

  const [selectedTopSlot, setSelectedTopSlot] = useState<TopSlot>(highestSlot);
  const [selectedDate, setSelectedDate] = useState<string>(highestSlot.start.slice(0, 10));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { completeCurrentStep, navigateToNextStep } = useContext(StepsUpdateContext);
  const { fetchTicket, setShowFeedback } = useContext(TicketContext);

  const navigationButtonsRef = createRef<HTMLDivElement>();

  const { t } = useTranslation();
  const { response: { pending, error: isBookingError, complete: isBookingCompleted }, doRequest } = usePost();
  useScrollToTop();

  if (isBookingCompleted && !isBookingError) {
    completeCurrentStep();
    navigateToNextStep();
  }

  const handleNextClick = (): void => {
    const { hashId, workOrder: { appointment: { id } } } = ticket;
    setIsLoading(true);

    const request = new TopSlotService()
      .bookSlot(hashId, id, selectedTopSlot)
      .then(async () => {
        // This is needed so on the next screen (confirm) new required (arrivalWindowStart ...) fields are available right away
        await fetchTicket();
        successfullyBookedAppointmentGA(selectedTopSlot);
        // This is to only show the survey right after resident creates appoitnment or see noTopSlots.
        // If they revisit the confirmation page after a while they shouldnt be able to submit feedback
        setShowFeedback(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

    doRequest(request);
  };

  const selectDate = useCallback((date: string) => {
    sendAnalyticsDateSelected(date);
    setSelectedDate(date);
  }, []);

  const selectSlot = useCallback((slot: TopSlot, index: number) => {
    setSelectedTopSlot(slot);
    selectedTopSlotIndexGA(index);
  }, []);

  useEffect(() => {
    selectDate(highestSlot.start.slice(0, 10));
    selectSlot(highestSlot, topSlots[highestSlot.start.substr(0, 10)].indexOf(highestSlot));
  }, [highestSlot, topSlots, selectDate, selectSlot]);

  useEffect(() => {
    onSlotBooking(pending);
  }, [onSlotBooking, pending]);

  if (pending) {
    return (
      <LoadingImages items={[{
        image: 'slotBooking',
        text: t('appointmentSection:moment'),
        ariaLabel: t('appointmentSection:moment'),
      }]} />
    );
  }

  return (
    <>
      {!!isBookingError && (
        <Alert
          text={t('alert:bookingError')}
          level="error"
        />
      )}

      <div>
        <DateBar
          slots={topSlots}
          startDate={minDate}
          endDate={maxDate}
          selectedDate={selectedDate}
          setSelectedDate={selectDate}
          onLoadMore={onLoadMore}
          canRetrieveMore={canRetrieveMore}
          areLoadingSlots={areLoadingSlots} />
        <div>
          {!!topSlots[selectedDate] && topSlots[selectedDate].map((topSlot: TopSlot, index: number) => (
            <TopSlotInfo
              key={index}
              isOptimal={topSlot.start === highestSlot.start}
              topSlot={topSlot}
              onSelect={(): void => {
                selectSlot(topSlot, index);
                scrollToElement(navigationButtonsRef.current);
              }}
              active={topSlot === selectedTopSlot}
            />
          ))}
        </div>
      </div>
      {!!selectedTopSlot && (
        <AppointmentConfirmationText
          ticket={ticket}
          stylesheet={stylesheet}
          selectedTopSlot={selectedTopSlot}
        />
      )}

      <div className={styles.appointmentContainer}>
        <Navigation rootRef={navigationButtonsRef}>
          <NavigationButton
            text={t('button:confirm')}
            nextDisabled={isLoading}
            buttonAction="next"
            onClickOverride={handleNextClick}
          />
        </Navigation>
      </div>
    </>
  );
};

export default AppointmentWithTopSlots;
