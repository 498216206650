import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from './Step.module.scss';
import { StepModel } from './interfaces/Step';
import { handleKeyboardSelect } from '../../helpers/Accessibility';
import { getHeaderAnimationName } from '../../helpers/HeaderAnimation';

interface StepProps {
  step: StepModel;
  index: number;
}

const Step: FC<StepProps> = ({
  step: { id, isCompleted, isDisabled }, index
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const animationName = getHeaderAnimationName();

  const isActive = pathname === `/${id}`;
  const stepClasses = classNames({
    'step-item': true, // for brands scss
    'step-item-completed': isCompleted, // for brands scss
    'step-item-disabled': isDisabled, // for brands scss
    [style.step]: true,
    [style.stepCompleted]: isCompleted,
    [style.stepDisabled]: isDisabled,
    [style.stepWithAnimation]: !!animationName
  });

  const stepContainerClasses = classNames({
    'step-container': true,  // for brands scss
    'step-container-active': isActive && !isCompleted, // for brands scss
    [style.stepContainer]: true,
    [style.stepContainerActive]: isActive && !isCompleted,
  });

  const stepNameClasses = classNames({
    [style.stepName]: true,
    [style.stepDisabledFont]: isDisabled && !isCompleted,
  });

  const handleClick = useCallback(() => {
    navigate(`/${id}`);
  }, [navigate, id]);

  const handleKeyDown = useMemo(() => handleKeyboardSelect(handleClick), [handleClick]);

  return (
    <li className={stepContainerClasses}>
      <button
        className={stepClasses}
        aria-label={t(`stepsBar:${id}`)}
        aria-disabled={isDisabled}
        aria-current={isActive ? 'page' : 'false'}
        onKeyDown={isDisabled ? undefined : handleKeyDown}
        onClick={isDisabled ? undefined : handleClick}
        tabIndex={isDisabled ? -1 : 0}
      >
        {isCompleted ? (
          <svg className={style.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className={style.checkmarkCircle} cx="26" cy="26" r="25" fill="none" />
            <path className={style.checkmarkCheck} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        ) :
          <span className={style.circle}>{index}</span>
        }

        <span className={stepNameClasses}>{t(`stepsBar:${id}`)}</span>
      </button>
    </li>
  );
};

export default Step;
