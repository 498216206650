import { memo, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import style from './BaseModal.module.scss';
import { FCWithChildren } from '../../interfaces/Shared';

interface BaseModalProps {
  ariaLabel?: string;
  preventCloseWithEscape?: boolean;
  className?: string;
  onClose: () => void;
}

const BaseModalComponent: FCWithChildren<BaseModalProps> = ({
  onClose, ariaLabel, preventCloseWithEscape, className, children
}) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const styles = classNames(style.baseModal, className);

  const handleOnClose = useCallback(() => {
    if (modalRef.current) {
      modalRef.current.close();
      onClose();
    }
  }, [onClose]);

  const handleKeyboardEvent = useCallback((event: Event) => {
    if (preventCloseWithEscape) {
      event.preventDefault();
    } else {
      handleOnClose();
    }
  }, [preventCloseWithEscape, handleOnClose]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  }, []);

  useEffect(() => {
    const dialog = modalRef.current;

    if (dialog) {
      dialog.addEventListener('cancel', handleKeyboardEvent);
    }

    return (): void => dialog?.removeEventListener('cancel', handleKeyboardEvent);
  }, [handleKeyboardEvent]);

  return (
    <dialog ref={modalRef} aria-label={ariaLabel} className={styles}>
      {children}
    </dialog>
  );
};

const BaseModal = memo(BaseModalComponent);

export default BaseModal;
