import classNames from 'classnames';

import { useFormatting } from '../../services/hooks/useFormatting';
import BlockBG from '../BlockBG/BlockBG';
import style from './Alert.module.scss';
import { IconNames } from '../MaterialIcon/MaterialIcon';
import { FCWithOptionalChild } from '../../interfaces/Shared';

interface AlertProps {
  text: string;
  icon?: IconNames;
  level?: 'error' | 'warn';
  textButton?: { text: string, onClick: () => void }
}

const Alert: FCWithOptionalChild<AlertProps> = ({ icon, text, level, textButton, children }) => {
  const { formattedText: alertText } = useFormatting(text);

  switch (level) {
    case 'warn':
      icon = icon || 'error';
      break;
    case 'error':
      icon = icon || 'error';
      break;
  }

  const alertClassNames = classNames(
    style.container,
    level ? style[`${level}Background`] : '',
  );

  const isSystemMessage = level === 'error' || level === 'warn';

  const iconClassNames = classNames(
    'material-icons',
    !isSystemMessage && 'partner-font-color',
    style.alertIcon,
    level ? style[`${level}Icon`] : '');

  return (
    <BlockBG classStyle={alertClassNames}>
      {!!icon && <i aria-hidden="true" className={iconClassNames}>{icon}</i>}
      <div>
        {!!alertText && <span className={style.alertText} dangerouslySetInnerHTML={{ __html: alertText }} />}
        {!!textButton && (
          <button
            className={style.alertTextButton}
            onClick={textButton.onClick}
            aria-label={textButton.text}
          >
            {textButton.text}
          </button>
        )}
        {children}
      </div>
    </BlockBG>
  );
};

export default Alert;
