import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Stylesheet } from '../../interfaces/Stylesheet';
import { Customer } from '../../interfaces/Customer';
import Alert from '../Alert/Alert';
import BlockBG from '../BlockBG/BlockBG';

import style from './AddressData.module.scss';

interface AddressDataProps {
  stylesheet: Stylesheet;
  customer: Customer;
}

const AddressData: FC<AddressDataProps> = ({ stylesheet, customer: { address } } ) => {
  const containerClasses = classnames('col col-sm-12 col-md-12 col-lg-6', style.sidebar);

  const { t } = useTranslation();

  return (
    <div className={containerClasses}>
      <BlockBG>
        <h2>{t('form:addressDetails')}</h2>
        <table className={style.addressTable}>
          <tbody>
            <tr>
              <td>{t('form:street')}</td>
              <td dangerouslySetInnerHTML={{ __html: address.street ? address.street : '' }} />
            </tr>
            <tr>
              <td>{t('form:zipCode')}</td>
              <td dangerouslySetInnerHTML={{ __html: address.postalCode ? address.postalCode : '' }} />
            </tr>
            <tr>
              <td>{t('form:city')}</td>
              <td dangerouslySetInnerHTML={{ __html: address.city ? address.city : '' }} />
            </tr>
          </tbody>
        </table>
      </BlockBG>
      <Alert
        text={stylesheet!.customerSection.alertMessageText}
        icon="push_pin"
      />
    </div>
  );
};

export default AddressData;
