export default {
  nl: {
    visitcard: {
      serviceVisits: 'Service bezoeken',
      yearsAtGuidion: 'Jaar bij Guidion',
      rating: 'Beoordeling',
    }
  },
  en: {
    visitcard: {
      serviceVisits: 'Service visits',
      yearsAtGuidion: 'Years at Guidion',
      rating: 'Rating',
    }
  }
};
