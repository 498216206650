import React, { ReactNode, useEffect } from 'react';
import classnames from 'classnames';

import styles from './StickyWrapper.module.scss';

interface StepsProps {
  children?: ReactNode;
  setIsSticky: (isSticky: boolean) => void;
  isSticky: boolean;
}

const StickyWrapperComponent = ({ children, setIsSticky, isSticky }: StepsProps): JSX.Element => {
  useEffect(() => {
    const sticky = document.getElementById('sticky')!.offsetTop;

    const onScroll = (): void => {
      if (window.scrollY >= sticky) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return (): void => window.removeEventListener('scroll', onScroll);
  }, [setIsSticky]);

  const stepsClasses = classnames(styles.stickyWrapper, {
    [styles.sticky]: isSticky,
  });

  return (
    <div className={stepsClasses} id="sticky">
      {children}
    </div>
  );
};

export const StickyWrapper = React.memo(StickyWrapperComponent);
