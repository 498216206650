import { FunctionComponent, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ContentfulImage } from '../../interfaces/Technician';
import style from './Modal.module.scss';
import Button from '../Button';
import BaseModal from '../BaseModal/BaseModal';

import { useFormatting } from '../../services/hooks/useFormatting';


export interface ModalProps {
  title: string;
  description: string;
  image?: ContentfulImage;
  small?: true;
  primaryLabel: string;
  primaryHandler: () => void;
  onClose: () => void;
  secondaryLabel?: string;
  secondaryHandler?: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({
  title,
  description,
  image,
  small,
  primaryLabel,
  primaryHandler,
  secondaryLabel,
  secondaryHandler,
  onClose,
}) => {
  const { formattedText: descriptionFormatted } = useFormatting(description);
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    buttonRef.current?.focus();

    return (): void => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <BaseModal className={classNames(style.popupWrapper, small ? style.small : '')} onClose={onClose}>
      <div data-testid="modal-content" className={style.popup} >
        {!!image && (
          <div
            className={style.image}
            style={{ background: `url(${image.file.url})` }}
            role="img"
            aria-label={t('errorAlts:onCallEmployee')}
          />
        )}
        <div className={style.popupContent}>
          <h3>{title}</h3>
          <p role="contentinfo" dangerouslySetInnerHTML={{ __html: descriptionFormatted }}></p>

          <div className={style.popupFooter}>
            {!!secondaryLabel && <Button variant="text" text={secondaryLabel} onClick={secondaryHandler} />}
            <Button buttonRef={buttonRef} onClick={primaryHandler} text={primaryLabel} />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default Modal;
