import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TopSlot } from '../../interfaces/TopSlot';
import { runXTimesAndReturnOutputs } from '../../helpers/RunXTimesHelpers';
import DateTime from '../../helpers/DateTime';
import { getEcoRating, isEcoLeafGreen } from '../../helpers/CalculateRating';

import styles from './TopSlotInfo.module.scss';
import { handleKeyboardSelect } from '../../helpers/Accessibility';

interface TopSlotInfoProps {
  topSlot: TopSlot;
  isOptimal?: boolean;
  onSelect: () => void;
  active: boolean;
}

export const TopSlotInfoComponent: FC<TopSlotInfoProps> = ({ topSlot: { start, end, grade }, isOptimal, active, onSelect }) => {
  const { t } = useTranslation();
  const timeStart = DateTime.getTimeStringFromDate(DateTime.roundToNearestMinutes(start));
  const timeEnd = DateTime.getTimeStringFromDate(DateTime.roundToNearestMinutes(end));

  const handleKeyDown = useMemo(() => handleKeyboardSelect(onSelect), [onSelect]);

  let slotAriaLabel = t('slots:ariaLabelSlot', {
    startTime: timeStart,
    endTime: timeEnd,
    grade: getEcoRating(grade).toString(),
  });

  if (isOptimal) {
    slotAriaLabel += `. ${t('slots:bestSlot')}`;
  }

  return (
    <button
      className={
        classNames(
          styles.slot,
          { active },
          'partner-highlight-border',
          active && 'partner-border-color')
      }
      onClick={onSelect}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      aria-label={slotAriaLabel}
      aria-current={active}
    >
      <div className={styles.slotInfo}>
        <div className={styles.selection}>
          <span className={classNames(styles.selector, active && 'partner-background')}>
            {<div className={classNames(styles.radioSelector, styles.radioOuter, active && 'partner-border-color')} />}
            {!!active && <div className={classNames(styles.radioSelector, styles.radioCenter, 'partner-background')} />}
          </span>
        </div>
        <p className={styles.date}>
          {` ${t('appointmentSection:separator')} `}
          <span>{`${timeStart} - ${timeEnd} ${t('appointmentSection:hour')}`}</span>
        </p>
        <div className={styles.route}>
          {runXTimesAndReturnOutputs(3, (index: number) => (
            <i key={index} className={
              classNames(
                'material-icons', // needed to apply correct font
                styles.materialIcons,
                { [styles.greenText]: isEcoLeafGreen(index, grade) })
            }>eco</i>
          ))}
        </div>
      </div >
      {!!isOptimal && <p className={classNames(styles.description, active && 'partner-background')}>{t('slots:bestSlot')}</p>}
    </button>
  );
};

export const TopSlotInfo = React.memo(TopSlotInfoComponent);
