export const scrollToElement = (targetEl: HTMLElement | null): void => {
  if (!targetEl) {
    return;
  }
  const buttonTop = targetEl.getBoundingClientRect().top -
    targetEl.clientHeight;
  const scrollDelta = Math.round(buttonTop - window.innerHeight / 2);
  const scrollTop = (document.documentElement.scrollTop || document.body.scrollTop) + scrollDelta;
  window.scrollTo({ top: scrollTop, behavior: 'smooth' });
};
