import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SaveNoSlotParams } from '../../interfaces/AvailableNoSlots';
import { NoTopSlot } from '../../interfaces/NoTopSlot';
import NoSlotSelectors from './NoSlotSelectors';
import style from './NoSlotForm.module.scss';
import Alert from '../Alert/Alert';
import Button from '../Button';

import { sendAnalyticsNoSlots } from '../../services/Analytics';
import TicketService from '../../services/TicketService';
import { StepsUpdateContext } from '../Steps/StepsContext';

interface NoSlotFormProps {
  noTopSlots: NoTopSlot[];
  hashId: string;
  workorderId: string;
}

const NoSlotForm: FC<NoSlotFormProps> = ({ noTopSlots, hashId, workorderId }) => {
  const { completeCurrentStep, navigateToNextStep } = useContext(StepsUpdateContext);
  const [noTopSlotsUpdated, setNoTopSlotsUpdated] = useState<NoTopSlot[]>(noTopSlots);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const selectedNoTopSlots = useMemo<NoTopSlot[]>(() => noTopSlotsUpdated
    .filter((noToSlotItem: NoTopSlot) => {
      return noToSlotItem.periods.some((period) => period.selected);
    })
    .map(slot => ({
      ...slot,
      periods: slot.periods.filter(period => period.selected)
    })), [noTopSlotsUpdated]);

  const selectedPeriodCount = useMemo<number>(() => selectedNoTopSlots.reduce((count: number, notTopSlotItem: NoTopSlot) => {
    return count + notTopSlotItem.periods.filter((period) => period.selected).length;
  }, 0), [selectedNoTopSlots]);

  const onComplete = useCallback(() => {
    completeCurrentStep();
    navigateToNextStep({ state: { selectedNoTopSlots } });
  }, [completeCurrentStep, navigateToNextStep, selectedNoTopSlots]);

  const saveNoTopslots = useCallback(async (): Promise<void> => {
    const params: SaveNoSlotParams = {
      version: 'new',
      noSlots: selectedNoTopSlots,
    };

    try {
      setLoading(true);
      await new TicketService().saveNoSlotInformation(hashId, workorderId, params);

      sendAnalyticsNoSlots(params);

      onComplete();
    } catch (error) {
      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [hashId, workorderId, selectedNoTopSlots, onComplete]);

  return (
    <div>
      <NoSlotSelectors noTopSlots={noTopSlotsUpdated} updateSlots={setNoTopSlotsUpdated} />
      <div className={style.extraInfoRoot}>
        <div>
          {!!error && (
            <div className={style.errorMessage} >
              <Alert
                icon= "error"
                text={t('alert:somethingWentWrong')}
              />
            </div>
          )}
        </div>
      </div>
      <div className={style.btnsSection}>
        <Button
          disabled={loading || selectedPeriodCount < 3}
          text={t('noslots:scheduleAutoBtn')}
          onClick={saveNoTopslots}
        />
      </div>
    </div>
  );
};

export default NoSlotForm;
