export default {
  nl: {
    button: {
      nextStep: 'Volgende stap',
      prevStep: 'Vorige stap',
      saveDetails: 'Gegevens opslaan',
      confirm: 'Bevestig afspraak',
      back: 'Terug',
      send: 'Verstuur',
      contactGuidion: 'Contact Guidion',
      faq: 'FAQ openen',
      cancelAppointment: 'Annuleren',
    }
  },
  en: {
    button: {
      nextStep: 'Next step',
      prevStep: 'Previous step',
      saveDetails: 'Save details',
      confirm: 'Confirm',
      back: 'Back',
      send: 'Send',
      contactGuidion: 'Contact Guidion',
      faq: 'Open FAQ',
      cancelAppointment: 'Cancel'
    }
  }
};
