import { createContext, useCallback, useState } from 'react';

import { Ticket } from '../interfaces/Ticket';
import { FCWithChild } from '../interfaces/Shared';

import { getTicketIdFromLocalStorage } from '../helpers/LocalStorage';
import TicketService from '../services/TicketService';

export interface TicketProviderStore {
  ticket: Ticket | undefined;
  isInErrorMode: boolean;
  showFeedback: boolean;
  setTicket: (ticket: Ticket | undefined) => void;
  fetchTicket: (id?: string) => Promise<Ticket | undefined>;
  setIsInErrorMode: (isInErrorMode: boolean) => void;
  setShowFeedback: (showFeedback: boolean) => void;
}

export const TicketContext = createContext({} as TicketProviderStore);

const TicketProvider: FCWithChild = ({ children }) => {
  const [ticket, setTicket] = useState<Ticket | undefined>(undefined);
  const [isInErrorMode, setIsInErrorMode] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const fetchTicket = useCallback(async (id?: string): Promise<Ticket | undefined> => {
    const ticketId = id || getTicketIdFromLocalStorage();

    if (!ticketId) {
      return;
    }

    const ticket = await new TicketService().getTicket(ticketId).catch(() => undefined);

    if (ticket) {
      setTicket(ticket);
    }

    return ticket;
  }, []);

  const store: TicketProviderStore = {
    ticket,
    isInErrorMode,
    showFeedback,
    fetchTicket,
    setTicket,
    setIsInErrorMode,
    setShowFeedback
  };

  return (
    <TicketContext.Provider value={store}>
      {children}
    </TicketContext.Provider>
  );
};

export { TicketProvider };

export const TicketConsumer = TicketContext.Consumer;
