export enum StepId {
  customer = 'customer',
  questions = 'questions',
  appointment = 'appointment',
  change = 'change',
  confirm = 'confirm'
}

export interface StepModel {
  id: StepId;
  isCompleted: boolean;
  isDisabled: boolean;
}
