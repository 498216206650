import { useEffect, useState } from 'react';

import { Technician } from '../../../interfaces/Technician';
import TechnicianService from '../../../services/TechnicianService';

export const useTechnician = (ticketHashId: string): Technician | undefined => {
  const [technician, setTechnician] = useState<Technician | undefined>(undefined);

  useEffect(() => {
    const loadTechnician = async (ticketHashId: string): Promise<void> => {
      const technician = await new TechnicianService().getTechnician(ticketHashId).catch(() => undefined);
      setTechnician(technician);
    };

    loadTechnician(ticketHashId);
  }, [ticketHashId]);

  return technician;
};
