import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import DateTime from '../../helpers/DateTime';
import { Maintenance } from '../../interfaces/Stylesheet';

import styles from './Error.module.scss';

interface MaintenanceErrorProps {
  maintenance: Maintenance;
}

const MaintenanceError: FunctionComponent<MaintenanceErrorProps> = ({ maintenance }) => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{maintenance.title}</h1>
      <p>{maintenance.description}</p>
      <table className={styles.statusTable}>
        <tr>
          <th dangerouslySetInnerHTML={{ __html: t('errorMaintenance:maintenanceStart') }} />
          <th dangerouslySetInnerHTML={{ __html: t('errorMaintenance:maintenanceEnd') }} />
          <th dangerouslySetInnerHTML={{ __html: t('errorMaintenance:maintenancePurpose') }} />
        </tr>
        <tr>
          <td dangerouslySetInnerHTML={
            { __html: DateTime.formatDate('d MMMM HH:mm', new Date(maintenance.startTime!)) }}
          />
          <td dangerouslySetInnerHTML={{ __html: DateTime.formatDate('d MMMM HH:mm', new Date(maintenance.endTime!)) }} />
          <td dangerouslySetInnerHTML={{ __html: maintenance.reason }} />
        </tr>
      </table>

      <p>
        <Button
          text={t('errorMaintenance:tryAgainButton')}
          onClick={(): void => {
            document.location.href = '/';
          }} />
      </p>
    </>
  );
};

export default MaintenanceError;
