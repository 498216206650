import { FC, useCallback, useContext, useState } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

import { StylesheetContext } from '../../contexts/StylesheetContext';
import { FaqSection } from '../../interfaces/Stylesheet';
import FaqSectionComponent from './FaqSection';
import { sendFAQstring } from '../../services/Analytics';
import FaqHeader from './FaqHeader';

import styles from './Faq.module.scss';

interface FaqSectionsProps {
  faqsSections: FaqSection[];
}

const sendFaqString = debounce((search: string) => {
  sendFAQstring(search);
}, 2000);

const FaqSections: FC<FaqSectionsProps> = ({ faqsSections }) => {
  const [ searchText, setSearchText ] = useState('');
  const { stylesheet } = useContext(StylesheetContext);
  const { t } = useTranslation();

  const getSections = (faqsSections2: FaqSection[]): JSX.Element[] => {
    return faqsSections2.map((section, i) => (
      <FaqSectionComponent section={section} key={section.contentTitle + i} searchText={searchText} />
    ));
  };

  const search = useCallback(
    (event: { target: { value: string }}): void => {
      setSearchText(event.target.value);
      sendFaqString(event.target.value);
    },
    []);

  if (!stylesheet) {
    return null;
  }

  return (
    <div>
      <FaqHeader>
        <div className={styles.searchBar}>
          <i aria-hidden="true" className="material-icons">search</i>
          <input type="search" placeholder={t('faq:search')} value={searchText} onChange={search} />
        </div>
      </FaqHeader>

      {getSections(faqsSections)}
    </div>
  );
};

export default FaqSections;
