import React from 'react';

export interface DoRequest<T> {
  (promise: Promise<T | void>): void;
}

interface Response<T> {
  data: T | null;
  complete: boolean;
  pending: boolean;
  error: null | Error | Record<string, unknown> | string;
}

export const usePost = <T>(): {
  response: Response<T>;
  doRequest: DoRequest<T>;
} => {
  const [request, setRequest] = React.useState<Promise<T | void> | null>(null);
  const [response, setResponse] = React.useState<Response<T>>({
    data: null,
    complete: false,
    pending: false,
    error: null
  });

  React.useEffect(() => {
    let isMounted = true;

    if (request) {
      setResponse({
        data: null,
        complete: false,
        pending: true,
        error: null
      });

      request
        .then((data) => {
          if (!isMounted) {
            return;
          }

          setResponse({
            data: data ? data : null,
            complete: true,
            pending: false,
            error: null
          });
        })
        .catch((error) => {
          if (!isMounted) {
            return;
          }

          setRequest(null);
          setResponse({
            data: null,
            complete: true,
            pending: false,
            error
          });
        });
    }

    return (): void => {
      isMounted = false;
    };
  }, [request]);

  return { response, doRequest: (promise: Promise<T | void>): void => setRequest(promise) };
};
