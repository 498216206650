import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Appointment } from '../../../interfaces/Appointment';
import { Urls } from '../../../interfaces/Calendar';
import styles from './Calendar.module.scss';
import Button from '../../../components/Button';
import MaterialIcon from '../../../components/MaterialIcon/MaterialIcon';

import getUrls from '../../../services/CalendarService';
import DateTime from '../../../helpers/DateTime';
import { sendCalendarOpened, sendCalendarSelected } from '../../../services/Analytics';

interface CalendarProps {
  text: string;
  appointment: Appointment;
  partnerName: string;
}

const CalendarComponent: FC<CalendarProps> = ({ text, appointment, partnerName }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen((previousValue) => !previousValue);
    sendCalendarOpened();
  }, []);

  const urls: Urls = useMemo(() => {
    const startTime = DateTime.roundToNearestMinutes(appointment.arrivalWindowStart || DateTime.today());
    const endTime = DateTime.roundToNearestMinutes(appointment.arrivalWindowEnd || DateTime.today());

    return getUrls({
      start: startTime.toISOString(),
      finish: endTime.toISOString(),
      title: partnerName + ' monteur komt langs',
      address: 'Thuis',
      description: ''
    });
  }, [appointment, partnerName]);

  const dropDownContent = useMemo<{ url: keyof Urls, label: string }[]>(() => [
    { url: 'google', label: 'Google' },
    { url: 'yahoo', label: 'Yahoo' },
    { url: 'ics', label: 'Ical' },
    { url: 'outlook', label: 'Outlook' },
  ], []);

  useEffect(() => {
    const onClose = (): void => setIsOpen(false);

    if (isOpen) {
      window.addEventListener('click', onClose);
    }

    return (): void => window.removeEventListener('click', onClose);
  }, [isOpen]);

  return (
    <div className={styles.calendarConfirm}>
      <Button icon="calendar_month" text={text} variant="outline" onClick={onClick} />
      {!!isOpen && (
        <ul id="calendar" className={styles.dropdownContent}>
          {!!dropDownContent.length && dropDownContent.map(({ url, label }) => (
            <li aria-label={label} key={label}>
              <MaterialIcon name="calendar_month" isPartnerFontColor size={20} />
              <a href={urls[url]} onClick={() => sendCalendarSelected(url)} target="_blank" rel="noopener noreferrer">{label}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CalendarComponent;
