import FaqsService from '../../services/FaqsService';
import { Faq, FaqSection } from '../../interfaces/Stylesheet';
import FaqItem from './FaqItem';
import { useFormatting } from '../../services/hooks/useFormatting';

import styles from './Faq.module.scss';

interface FaqSectionProps {
  section: FaqSection;
  searchText?: string;
}

const FaqSectionComponent = ({ section, searchText }: FaqSectionProps): JSX.Element => {
  const { formattedText: sectionTitle } = useFormatting(FaqsService.highlightText(section.sectionTitle, searchText));

  const getFaqs = (faqs: Faq[]): JSX.Element[] => {
    return faqs.map((faq, i) => (
      <FaqItem faq={faq} key={faq.title + i} searchText={searchText} />
    ));
  };

  return (
    <div>
      {section.faqs.length > 0 && !!section.sectionTitle && <h2 dangerouslySetInnerHTML={{ __html: sectionTitle }} />}

      {section.faqs.length > 0 && (
        <ul className={styles.faqSection}>
          {getFaqs(FaqsService.filterFaqs(section.faqs, searchText))}
        </ul>
      )}
    </div>
  );
};

export default FaqSectionComponent;
