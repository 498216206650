import React from 'react';
import StepsService from '../../services/StepsService';
import { StepModel } from './interfaces/Step';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { TicketContext } from '../../contexts/TicketContext';
import { SupportedErrorTypes } from '../../services/ErrorService';
import { setNextStepGA } from '../../services/Analytics';
import { usePrevious } from '../../services/hooks/usePrevious';
import { FCWithChild } from '../../interfaces/Shared';

export interface StepsProviderStore {
  steps: StepModel[];
}

export interface StepsUpdateProviderStore {
  setBasicSteps: (basicSteps: StepModel[]) => void;
  completeCurrentStep: () => void;
  navigateToNextStep: (navigationOptions?: NavigateOptions) => void;
  navigateToPrevStep: () => void;
  navigateToActiveStep: () => void;
  navigateToError: (id: SupportedErrorTypes) => void;
  disableAllSteps: () => void;
  disableStepsOnRight: () => void;
}

export const StepsContext = React.createContext({} as StepsProviderStore);
export const StepsUpdateContext = React.createContext({} as StepsUpdateProviderStore);

const StepsProvider: FCWithChild = ({ children }) => {
  const { ticket } = React.useContext(TicketContext);
  const { isInErrorMode } = React.useContext(TicketContext);
  const defaultSteps = StepsService.getDefaultSteps(ticket, isInErrorMode);
  const [steps, setSteps] = React.useState<Array<StepModel>>(defaultSteps);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const previousInErrorMode = usePrevious<boolean>(isInErrorMode);

  React.useEffect(() => {
    if (typeof previousInErrorMode !== 'boolean' || previousInErrorMode === isInErrorMode) {
      return;
    }

    const newSteps = isInErrorMode
      ? steps.map(step => ({ ...step, isDisabled: true }))
      : StepsService.getDefaultSteps(ticket, isInErrorMode);

    setSteps(newSteps);
  }, [isInErrorMode, previousInErrorMode, steps, ticket]);

  if (steps.length === 0 && defaultSteps.length > 0) {
    setSteps(defaultSteps);
  }

  const getCurrentStepIndex = (): number => steps.findIndex(item => `/${item.id}` === pathname);

  const finishStepsOnLeft = (nextStepIndex: number): StepModel[] => {
    const updatedSteps = steps.map((step, index) => {
      if (index === nextStepIndex) {
        step.isDisabled = false;
        step.isCompleted = true;
      }

      if (index < nextStepIndex) {
        step.isDisabled = true;
        step.isCompleted = true;
      }

      return step;
    });

    return updatedSteps;
  };

  const completeCurrentStep = (): void => {
    const newSteps = [...steps];
    const index = getCurrentStepIndex();
    const nextStepIndex = index + 1;

    if (nextStepIndex < steps.length - 1) {
      newSteps[index] = { ...newSteps[index], isCompleted: true, isDisabled: false };
      newSteps[nextStepIndex] = { ...newSteps[nextStepIndex], isDisabled: false };
    } else if (nextStepIndex === steps.length - 1) {
      finishStepsOnLeft(nextStepIndex);
    }

    if (newSteps[nextStepIndex]) {
      setNextStepGA(newSteps[nextStepIndex].id);
    }

    setSteps(newSteps);
  };

  const navigateToNextStep = (navigationOptions?: NavigateOptions): void => {
    const nextStepIndex = getCurrentStepIndex() + 1;

    if (nextStepIndex < steps.length) {
      navigate(`/${steps[nextStepIndex].id}`, navigationOptions);
    }
  };

  const navigateToPrevStep = (): void => {
    const prevStepIndex = getCurrentStepIndex() - 1;

    if (prevStepIndex >= 0) {
      navigate(`/${steps[prevStepIndex].id}`);
    }
  };

  const navigateToActiveStep = (): void => {
    const activeStep = StepsService.getActiveStep(steps);

    navigate(`/${activeStep.id}`);
  };

  const navigateToError = (id: SupportedErrorTypes): void => {
    navigate(`/error/${id}`);
  };

  const setBasicSteps = (basicSteps: StepModel[]): void => {
    const newSteps = [...steps];

    basicSteps.forEach(basicStep => {
      const index = steps.findIndex(item => item.id === basicStep.id);
      newSteps[index] = { ...basicStep };
    });

    setSteps(newSteps);
  };

  const disableAllSteps = (): void => {
    const updatedSteps = steps.map((step) => {
      step.isDisabled = true;
      step.isCompleted = false;
      return step;
    });
    setSteps(updatedSteps);
  };

  const disableStepsOnRight = (): void => {
    const currentStep = getCurrentStepIndex();
    const updatedSteps = steps.map((step, index) => {
      if (index === currentStep) {
        step.isDisabled = false;
        step.isCompleted = false;
      }

      if (index > currentStep) {
        step.isDisabled = true;
        step.isCompleted = false;
      }

      return step;
    });
    setSteps(updatedSteps);
  };

  const store = { steps };

  return (
    <StepsContext.Provider value={store}>
      <StepsUpdateContext.Provider value={{
        completeCurrentStep,
        disableAllSteps,
        disableStepsOnRight,
        navigateToActiveStep,
        navigateToNextStep,
        navigateToPrevStep,
        navigateToError,
        setBasicSteps
      }}>
        {children}
      </StepsUpdateContext.Provider>
    </StepsContext.Provider>
  );
};

export { StepsProvider };

export const StepsConsumer = StepsContext.Consumer;
