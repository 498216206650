export default {
  nl: {
    appointmentSection: {
      bookingText: 'De afspraak wordt ingepland',
      moment: 'Een klein moment alstublieft...',
      hour: 'uur',
      mobileHeader: 'Selecteer een afspraakmogelijkheid',
      selection: 'Selectie',
      date: 'Datum en tijdslot',
      route: 'Route',
      separator: 'tussen'
    },
    appointment: {
      title: 'Afspraak plannen',
      goBack: 'Terug naar vragen',
      editTitle: 'Afspraak wijzigen',
      loadingQuestions: 'De vragen worden geladen ...',
      savingAnswers: 'De antwoorden worden opgeslagen ...',
    },
    appointmentLoading: {
      selectingBestExpert: 'Beste experts in de buurt selecteren ...',
      calculatingEcoRoute: 'Milieuvriendelijke routes berekenen ...',
    },
    slots: {
      showPreviousSlots: 'Laat vorig tijdslot zien',
      showNextSlots: 'Laat volgend tijdslot zien',
      loadMoreSlots: 'Laad meer sloten',
      loadingMoreSlots: 'Sloten aan het laden',
      bestSlot: 'Dit is de beste optie voor het milieu én onze expert.',
      yourChoice: 'De afspraak is op [b][day] [date] [/b] tussen [b][startTime][/b] en [b][endTime][/b] uur.',
      ariaLabelSlot: 'Tijdslot tussen {{startTime}} en {{endTime}}, duurzaamheidsscore is {{grade}} van de 3',
    },
    noSlotsPopup: {
      confirmButton: 'Oké, ik begrijp het'
    },
    noslots: {
      title: 'Wanneer kan de monteur het beste bij je langskomen?',
      alertInfo: 'Je kan maar in één week beschikbaarheid opgeven. Lukt deze week niet, klik dan onderaan op ‘>’ .',
      alertError: 'Er is iets fout gegaan, wij konden onze beschikbare tijdsloten niet laden.',
      alertButton: 'Probeer het opnieuw',
      thanksTitle: 'Bedankt!',
      entireDay: 'Hele dag',
      scheduleAutoBtn: 'Bevestig beschikbaarheid',
    },
    noslotsOld: {
      title: 'Geen afspraak mogelijkheden gevonden',
      thanksTitle: 'Bedankt!',
      available: 'Beschikbaar',
      notAvailable: 'Niet beschikbaar',
      extrainfo: 'Extra informatie toevoegen aan de afspraak?',
      comment: 'Opmerking',
      nocomment: 'N.V.T',
      autoSchedule: 'Automatisch inplannen',
      autoScheduleExplanation: 'Liever een automatisch ingeplande afspraak of telefonisch contact om de afspraak te bevestigen? De afspraakbevestiging wordt ook altijd per mail gestuurd.',
      callToConfirmBtn: 'Bel me om te bevestigen',
      scheduleAutoBtn: 'Automatisch inplannen',
    },
  },
  en: {
    appointmentSection: {
      bookingText: 'The appointment is being made',
      moment: 'One moment please...',
      hour: '',
      mobileHeader: 'Select a date',
      selection: 'Selection',
      date: 'Date and time',
      route: 'Route',
      separator: 'between'
    },
    appointment: {
      title: 'Make an appointment',
      goBack: 'Back to questions',
      editTitle: 'Change appointment',
      loadingQuestions: 'Loading questions ...',
      savingAnswers: 'Saving answers...',
    },
    appointmentLoading: {
      selectingBestExpert: 'Selecting the best expert around you',
      calculatingEcoRoute: 'Calculating environmentally friendly routes ...',
    },
    slots: {
      showPreviousSlots: 'Show previous Slots',
      showNextSlots: 'Show next Slots',
      loadMoreSlots: 'Load more Slots',
      loadingMoreSlots: 'Loading more Slots',
      bestSlot: 'This is the best option for the environment and our expert.',
      yourChoice: 'The appointment is on [b][day] [date] [/b] between [b][startTime][/b] and [b][endTime][/b] o\'clock.',
      ariaLabelSlot: 'Timeslot between {{startTime}} and {{endTime}}, eco grade score is {{grade}} out of 3',
    },
    noSlotsPopup: {
      confirmButton: 'Ok, I understand'
    },
    noslots: {
      title: 'When is the best time for the technician to visit you?',
      alertInfo: "You can only select dates in one week. If the current week is not possible, click '>' for next week.",
      alertError: "Something went wrong, we couldn't load our available time slots.",
      alertButton: 'Please try again',
      thanksTitle: 'Thanks!',
      entireDay: 'Entire day',
      scheduleAutoBtn: 'Confirm availability',
    },
    noslotsOld: {
      title: 'No appointment options found',
      thanksTitle: 'Thanks!',
      available: 'Available',
      notAvailable: 'Not available',
      extrainfo: 'Do you want to add additional information?',
      comment: 'Comment',
      nocomment: 'N/A',
      autoSchedule: 'Auto scheduling',
      autoScheduleExplanation: 'Do you want us to schedule the appointment automatically or do you want us to contact you by phone to confirm the appointment? You will always receive an appointment confirmation by email.',
      callToConfirmBtn: 'Call me to confirm',
      scheduleAutoBtn: 'Auto scheduling',
    },
  }
};
