import { Faq } from '../interfaces/Stylesheet';

const escapeChars = (text: string): string => {
  return text.replace(/[^a-z0-9-'\s]/g, '');
};

const highlightText = (text: string, search: string | undefined): string => {
  let t = text;

  if (text && search) {
    t = text.replace(
      new RegExp('(?![^\[]*]|[^<]*>)(' + escapeChars(search) + ')', 'gi'),
      '<span class="highlighted">$1</span>'
    );
  }

  return t;
};

const filterFaqs = (faqs: Faq[], search: string | undefined): Faq[] => {
  if (!search) {
    return faqs;
  }

  return faqs.filter(faq => {
    const faqText = faq['text'].toLowerCase().indexOf(escapeChars(search)) !== -1;
    const faqTitle = faq['title'].toLowerCase().indexOf(escapeChars(search)) !== -1;

    return faqTitle || faqText;
  });
};

export default {
  highlightText,
  filterFaqs,
  escapeChars
};
