export default {
  nl: {
    cancel: {
      title: 'Annuleren',
      errorMessage: 'De afspraak kon helaas niet worden geannuleerd. Probeer het later opnieuw.',
      successMessage: 'Bedankt voor de bevestiging, de afspraak wordt geannuleerd.',
      makeAppointment: 'Toch liever een afspraak maken? ',
      makeAppointmentLink: 'Klik hier om een afspraak te maken.',
      popupTitle: 'Weet je zeker dat je de afspraak wilt annuleren?',
      popupDescription: 'Na annulering is de afspraaklink niet meer geldig. Voor een nieuwe afspraak zul je contact op moeten nemen met de klantenservice van {{partnerName}}.',
      confirmCancelButton: 'Ik wil annuleren',
      rejectCancelButton: 'Nee, toch niet',
    }
  },
  en: {
    cancel: {
      title: 'Cancel',
      errorMessage: 'Unfortunately, the appointment could not be cancelled. Try again later.',
      successMessage: 'Thank you for the confirmation, your appointment will be cancelled.',
      makeAppointment: 'Do you still want to make an appointment for your services? ',
      makeAppointmentLink: 'Click here to make an appointment.',
      popupTitle: 'Are you sure you want to cancel the appointment?',
      popupDescription: 'After cancelling, the appointment link is not valid anymore. In order to get a new appointment, you will need to contact the customer service of {{partnerName}}.',
      confirmCancelButton: 'I want to cancel',
      rejectCancelButton: "No, don't cancel",
    }
  }
};
