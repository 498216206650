export default {
  nl: {
    faq: {
      title: 'Veelgestelde vragen',
      text: 'Bekijk hieronder de antwoorden op de veelgestelde vragen over de afspraak met onze installatiepartner.',
      search: 'Zoeken in FAQ',
      thereAreNoFaqAvailable: 'Er zijn geen FAQ beschikbaar'
    },
  },
  en: {
    faq: {
      title: 'Frequently asked questions',
      text: 'Check the answers to the frequently asked questions about the appointment with our installation partner below.',
      search: 'Search in FAQ',
      thereAreNoFaqAvailable: 'There are no FAQ available'
    },
  }
};
