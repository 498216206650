import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Routes } from 'react-router-dom';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { beforeSend } from './SentryHelpers';


Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  ignoreErrors: [/@webkit-masked-url/],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
  ],
  beforeSend,
  tracesSampleRate: 0,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
