export default {
  nl: {
    generalAlts: {
      loading: 'Laden',
    },
    headerAlts: {
      nlFlag: 'Vlag van Nederland',
      ukFlag: 'Vlag van Groot Brittannië',
    },
    errorAlts: {
      onCallEmployee: 'Medewerker Guidion'
    },
    expertCardAlts: {
      expertPhotograph: 'Foto van expert'
    }
  },
  en: {
    generalAlts: {
      loading: 'Loading',
    },
    headerAlts: {
      nlFlag: 'Flag of the Netherlands',
      ukFlag: 'Flag of Great Britain',
    },
    errorAlts: {
      onCallEmployee: 'Guidion Employee'
    },
    expertCardAlts: {
      expertPhotograph: "Expert's photograph"
    }
  }
};
