import { createContext, useState } from 'react';

import { Customer } from '../interfaces/Customer';
import { FCWithChild } from '../interfaces/Shared';

export interface CustomerFormProviderStore {
  customer: Customer | undefined;
  setCustomer: (customer: Customer) => void;
}

export const CustomerFormContext = createContext({} as CustomerFormProviderStore);

const CustomerFormProvider: FCWithChild = ({ children }) => {
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);

  return (
    <CustomerFormContext.Provider value={{ customer, setCustomer }}>
      {children}
    </CustomerFormContext.Provider>
  );
};

export { CustomerFormProvider };

export const CustomerFormConsumer = CustomerFormContext.Consumer;
