export default {
  nl: {
    currency: {
      defaultFormat: '{{amount, currency(EUR)}}',
    },
  },
  en: {
    currency: {
      defaultFormat: '{{amount, currency(EUR)}}',
    },
  }
};
