import { useContext, useEffect } from 'react';
import { StylesheetContext } from '../../contexts/StylesheetContext';


const usePageTitle = (pageTitle: string): void => {
  const { stylesheet } = useContext(StylesheetContext);

  useEffect(() => {
    document.title = `${stylesheet?.settings.pageTitle ?? ''} ${pageTitle}`;
  }, [stylesheet, pageTitle]);
};

export default usePageTitle;
