import DateTime from '../helpers/DateTime';
import Language from '../helpers/Language';

export interface DateRange {
  start: string;
  finish: string;
}

interface ConvertDateTagOptions {
  range?: DateRange;
  roundDurationTo?: number
}

const convertDateTag = (text: string, replaceDate: string, options?: ConvertDateTagOptions): string => {
  const locale = Language.getLanguage();
  const matches = text.match(/\[(.+?)\]/g);

  if (text && matches && replaceDate) {
    text = text.replace(/\[(.+?)\]/g, (param: string) => {
      switch (param) {
        case '[plandate]':
        case '[paydate]':
          return DateTime.formatDate('EEEE d MMMM yyyy', DateTime.roundToNearestMinutes(replaceDate));
        case '[day]':
          return DateTime.formatDate('EEEE', DateTime.roundToNearestMinutes(replaceDate));
        case '[date]':
          return DateTime.formatDate('d MMMM yyyy', DateTime.roundToNearestMinutes(replaceDate));
        case '[shortDate]':
          if (locale === 'en') {
            return DateTime.formatDate('MMMM do', DateTime.roundToNearestMinutes(replaceDate));
          }

          return DateTime.formatDate('d MMMM', DateTime.roundToNearestMinutes(replaceDate));
        case '[startTime]':
          if (options?.range?.start && options?.range?.finish) {
            replaceDate = options.range.start;
          }

          return DateTime.formatDate('HH:mm', DateTime.roundToNearestMinutes(replaceDate));
        case '[duration]':
          if (options?.range){
            return DateTime.getDurationRounded(
              new Date(options.range.start),
              new Date(options.range.finish),
              locale,
              options.roundDurationTo
            );
          }

          return '';
        case '[endTime]':
          if (options?.range?.start && options?.range?.finish) {
            replaceDate = options.range.finish;
          }

          return DateTime.formatDate('HH:mm', DateTime.roundToNearestMinutes(replaceDate));
        default:
          return param;
      }
    });
  }

  return text;
};

const convertHtmlTag = (value: string): string => {
  let val = value;

  if (val) {
    val = val.replace(/\[b\](.*?)\[\/b\]/g, '<strong>$1</strong>');
    val = val.replace(/(?!\[)a="(?!\/)(?!https?)(?!tel\:)/, 'a="https:\/\/');
    const phoneNumber = (/\[a="tel\:([^"]+)"\]/g).exec(val)?.[1].split('').join(' ');
    val = val.replace(/\[a="([^"]+)"\](.*?)\[\/a\]/g, '<a href="$1" target="_blank">$2</a>');
    val = val.replace(/href="tel:([^"]+)"/g, `href="tel:$1" aria-label="${phoneNumber}"` );
  }

  return val;
};

const convertTicketTag = (value: string, ticketId: string): string => {
  let val = value;

  if (val) {
    val = val.replace(':ticketId', ticketId);
  }

  return val;
};

export default {
  convertHtmlTag,
  convertDateTag,
  convertTicketTag
};
