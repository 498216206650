import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Ticket } from '../../interfaces/Ticket';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { useFormatting } from '../../services/hooks/useFormatting';

import NoSlotForm from '../../components/NoSlots/NoSlotForm';
import Thanks from '../../components/Thanks/Thanks';
import Modal from '../../components/Modal/Modal';

import styles from './Appointment.module.scss';
import { NoTopSlot } from '../../interfaces/NoTopSlot';
import Alert from '../../components/Alert/Alert';
import NoSlotFormOld from '../../components/NoSlots/NoSlotFormOld';

interface AppointmentWithoutTopSlotsProps {
  stylesheet: Stylesheet;
  noTopSLots: NoTopSlot[];
  ticket: Ticket;
  lowAvailabilityAlert?: React.ReactNode;
  refetchNoTopSlots: () => void;
}

const AppointmentWithoutTopSlots: FC<AppointmentWithoutTopSlotsProps> = ({
  noTopSLots, ticket, stylesheet, lowAvailabilityAlert, refetchNoTopSlots
}) => {
  const [oldNoTopSlotIsSaved, setOldNoTopSlotIsSaved] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const {
    appointmentSection: { noSlotsBlock, noSlotsPopupTitleBlock, noSlotsPopupDescriptionBlock, noSlotsPopupImage }
  } = stylesheet;
  const { formattedText: noSlotsText } = useFormatting(noSlotsBlock.value);
  const { formattedText: noSlotsPopupTitle } = useFormatting(noSlotsPopupTitleBlock.value);
  const { formattedText: noSlotsPopupDescription } = useFormatting(noSlotsPopupDescriptionBlock.value);

  const { t } = useTranslation();

  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  if (oldNoTopSlotIsSaved) {
    return <Thanks />;
  }

  const shouldDisplayOldNoTopslots: boolean = ticket.abTesting !== 'no-topslots';
  const hasNoTopSlots: boolean = !!noTopSLots.length;

  return (
    <>
      <h1>{t(shouldDisplayOldNoTopslots ? 'noslotsOld:title' : 'noslots:title')}</h1>
      <p role="note" dangerouslySetInnerHTML={{ __html: noSlotsText }} />
      {lowAvailabilityAlert}
      {!shouldDisplayOldNoTopslots && (
        <Alert
          icon="info"
          level={!hasNoTopSlots ? 'error' : undefined}
          text={!hasNoTopSlots ? t('noslots:alertError') : t('noslots:alertInfo')}
          textButton={!hasNoTopSlots ? { text: t('noslots:alertButton'), onClick: refetchNoTopSlots } : undefined}
        />
      )}
      {(shouldDisplayOldNoTopslots || hasNoTopSlots) && (
        <div className={styles.noSlots}>
          {shouldDisplayOldNoTopslots ? (
            <NoSlotFormOld
              hashId={ticket.hashId}
              workorderId={ticket.workOrder.id}
              onComplete={(): void => setOldNoTopSlotIsSaved(true)}
            />
          ) : (
            <NoSlotForm
              noTopSlots={noTopSLots}
              hashId={ticket.hashId}
              workorderId={ticket.workOrder.id}
            />
          )}
        </div>
      )}

      {!!modalVisible && shouldDisplayOldNoTopslots && (
        <Modal
          image={noSlotsPopupImage}
          title={noSlotsPopupTitle}
          description={noSlotsPopupDescription}
          onClose={onClose}
          primaryLabel={t('noSlotsPopup:confirmButton')}
          primaryHandler={onClose}
        />
      )}
    </>
  );
};

export default AppointmentWithoutTopSlots;
