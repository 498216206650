export default {
  nl: {
    errorMaintenance: {
      contactButton: 'Neem contact op',
      maintenanceStart: 'Starttijd',
      maintenanceEnd: 'Eindtijd',
      maintenancePurpose: 'Reden',
      tryAgainButton: 'Probeer opnieuw'
    },
  },
  en: {
    errorMaintenance: {
      contactButton: 'Contact us',
      maintenanceStart: 'Start time',
      maintenanceEnd: 'End time',
      maintenancePurpose: 'Scope',
      tryAgainButton: 'Try again'
    },
  }
};
