import React, { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { AvailableNoSlots, DayPeriodType, DayType } from '../../interfaces/AvailableNoSlots';
import { NoSlotButton } from './NoSlotButton';
import style from './NoSlotSelectorsOld.module.scss';
import { supportedDays } from './NoSlotHelper';

interface NoSlotSelectorsProps {
  slots: AvailableNoSlots;
  updateSlots: (newSlots: AvailableNoSlots) => void;
}

const morningPeriod = 'morning';
const afternoonPeriod = 'afternoon';

const NoSlotSelectorsOld: FC<NoSlotSelectorsProps> = ({ slots, updateSlots }) => {
  const { t } = useTranslation();

  const setPeriodOfDay = (day: DayType, period: DayPeriodType, newValue: boolean): void => {
    // We want a deep copy to ensure we remove memory references
    const newSlots: AvailableNoSlots = JSON.parse(JSON.stringify(slots));
    newSlots[day][period] = newValue;
    updateSlots(newSlots);
  };

  return (
    <div className={style.root}>
      <div/>
      <span className={style.label}>{t('timeofday:morning')}</span>
      <span className={style.label}>{t('timeofday:afternoon')}</span>

      {supportedDays.map((day) => (
        <React.Fragment key={day}>
          <span className={classnames(style.label, style.labelLong)}>{t(`days:${day}`)}</span>
          <span
            role="banner"
            aria-label={t(`days:${day}`)}
            className={classnames(style.label, style.labelShort)}
          >
            {t(`daysShort:${day}`)}
          </span>
          <NoSlotButton
            text="8:00 - 12:00"
            selected={slots[day][morningPeriod]}
            onSelect={(newValue): void => {
              setPeriodOfDay(day, morningPeriod, newValue);
            }}
          />
          <NoSlotButton
            text="12:00 - 20:00"
            selected={slots[day][afternoonPeriod]}
            onSelect={(newValue): void => {
              setPeriodOfDay(day, afternoonPeriod, newValue);
            }}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default NoSlotSelectorsOld;
