import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CompletedSignature.module.scss';

interface CompletedSignatureProps {
  customerSigned: boolean;
}

const CompletedSignature: FC<CompletedSignatureProps> = ({ customerSigned }): JSX.Element => {
  const { t } = useTranslation();
  const label = customerSigned ? 'accepted' : 'declined';
  const acceptedImage = '/assets/accepted-1c1da34a33eb46dd40ef5dfdb9003caf.png';
  const declinedImage = '/assets/declined-081907505c4c3346bd00dcb08906e3cd.png';

  return (
    <div className={styles.completedSignatureContainer}>
      <img src={customerSigned ? acceptedImage : declinedImage} width={155} height={122} alt={label} />
      <h3>{t(`signature:completedSignature:${label}:title`)}</h3>
      <p>{t(`signature:completedSignature:${label}:description`)}</p>
    </div>
  );
};

export default CompletedSignature;
