import { TFunction } from 'i18next';

import { Customer, CustomerFormFieldErrors } from '../../interfaces/Customer';
import { Ticket } from '../../interfaces/Ticket';
import { SaveStatus } from './interfaces/Customer';

import TicketService from '../../services/TicketService';
import ValidationService from '../../services/ValidationService';

interface OnSaveParams {
  setIsLoading: (value: boolean) => void,
  setTicket: (ticket: Ticket) => void,
  setSaveStatus: (saveStatus: SaveStatus) => void,
  customer: Customer | undefined,
  ticket: Ticket | undefined,
  initialSaveStatus: SaveStatus,
}

type OnFieldChange = <T extends keyof Customer>(params: {
  value: Customer[T],
  fieldName: T,
  customer: Customer | undefined,
  setCustomer: (customer: Customer) => void
}) => void;

const onSave = async ({
  setIsLoading, setTicket, setSaveStatus, customer, ticket, initialSaveStatus
}: OnSaveParams): Promise<boolean> => {
  let isCustomerUpdated = false;

  if (customer && ticket) {
    setSaveStatus({ ...initialSaveStatus });
    setIsLoading(true);
    try {
      await new TicketService().updateCustomer(ticket.hashId, customer);

      setTicket({ ...ticket, customer });
      isCustomerUpdated = true;
    } catch {
      setSaveStatus({ ...initialSaveStatus, error: true });
    } finally {
      setIsLoading(false);
    }
  }

  return isCustomerUpdated;
};

const onFieldChange: OnFieldChange = ({
  value, fieldName, customer, setCustomer
}): void => {
  if (customer) {
    const newCustomer: Customer = { ...customer };
    newCustomer[fieldName] = value;
    setCustomer(newCustomer);
  }

  return undefined;
};


const getInitialFieldErrors = (): CustomerFormFieldErrors => ({
  email: undefined,
  phoneNumber: undefined,
  mobileNumber: undefined,
});

const validateFields = (
  customer: Customer | undefined,
  setFieldErrors: (next: CustomerFormFieldErrors) => void,
  t: TFunction
) => {
  if (!customer) return false;

  const { email, phoneNumber, mobileNumber } = customer;

  const nextFieldErrors = getInitialFieldErrors();

  if (!email) {
    nextFieldErrors.email = t('customerSection:formErrors:emailRequired');
  } else if (!ValidationService.isEmailValid(email)){
    nextFieldErrors.email = t('customerSection:formErrors:emailInvalid');
  }

  if (!phoneNumber && !mobileNumber) {
    nextFieldErrors.phoneNumber = t('customerSection:formErrors:phoneNumberRequired');
    nextFieldErrors.mobileNumber = t('customerSection:formErrors:phoneNumberRequired');
  }

  if (phoneNumber && !ValidationService.isPhoneValid(phoneNumber)) {
    nextFieldErrors.phoneNumber = t('customerSection:formErrors:phoneNumberInvalid');
  }

  if (mobileNumber && !ValidationService.isPhoneValid(mobileNumber)) {
    nextFieldErrors.mobileNumber = t('customerSection:formErrors:phoneNumberInvalid');
  }

  setFieldErrors(nextFieldErrors);

  const isValid = !Object.values(nextFieldErrors).some(fieldError => fieldError);

  return isValid;
};

export default {
  onFieldChange,
  onSave,
  getInitialFieldErrors,
  validateFields,
};
